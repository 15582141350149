import React, { useEffect, useState } from "react";
import { usePostAPIJson } from "../Custom-Hook/usePostAPIJson";
import Calendar from "react-calendar";
 let baseUrl = process.env.REACT_APP_IN_USE_LIVE_URL;
export default function DoctorSlotBooking({
  doctorId,
  setSteps,
  setSelectedTimeModal,
  setSelectedDateModal,
}) {
  let getDocDetailsUrl = `${baseUrl}/getDoctordetailByUser`;
  const { data, postData: getDocdetails } = usePostAPIJson(getDocDetailsUrl);

  const [date, setDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(null);
  const [categorizedTimes, setCategorizedTimes] = useState([]);
  
  const handleSelectTime = (time) => {
    const selectedDate = new Date(date);
    const currentTime = new Date();

    // Check if the selected date is today's date
    const isToday =
      selectedDate.getDate() === currentTime.getDate() &&
      selectedDate.getMonth() === currentTime.getMonth() &&
      selectedDate.getFullYear() === currentTime.getFullYear();

    if (isToday) {
      let selectedHours;
      let currentTimes = new Date();
      const selectedHourMinute = time.split(":");
      const selectedDates = new Date();
      selectedHours = parseInt(selectedHourMinute[0], 10);
      const selectedMinutes = parseInt(selectedHourMinute[1].slice(0, -3), 10); // Remove AM/PM
      const period = selectedHourMinute[1].slice(-2); // Extract AM/PM

      // Adjust hours for PM time
      if (period == "PM" && selectedHours < 12) {
        selectedHours += 12;
      }

      selectedDates.setHours(selectedHours);
      selectedDates.setMinutes(selectedMinutes);

      // Compare with the current time
      if (selectedDates.getTime() < currentTimes.getTime()) {
        alert("The selected time has already passed for toda warning");
        return;
      } else {
        setSelectedTime(time);
        setSelectedTimeModal(time);
        setSteps(4);
        // setModalContent("patientList");
      }
    } else {
      setSelectedTime(time);
      setSelectedTimeModal(time);
      setSteps(4);

      //   setModalContent("patientList");
    }

    // Add your logic for handling the selected time
  };

  function generateTimeSlots(
    morningStartTime,
    morningEndTime,
    eveningStartTime,
    eveningEndTime
  ) {
    const addFifteenMinutes = (time) => {
      const nextTime = new Date(time);
      nextTime.setMinutes(nextTime.getMinutes() + 15); // Change to add 15 minutes
      return nextTime;
    };

    const createTimeSlots = (startTime, endTime, category) => {
      const timeSlots = [];
      const startDateTime = new Date("2024-01-01 " + startTime);
      const endDateTime = new Date("2024-01-01 " + endTime);

      let currentTime = startDateTime;

      while (currentTime < endDateTime) {
        const formattedStartTime = currentTime.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        });

        const nextTime = addFifteenMinutes(currentTime);
        const formattedEndTime = nextTime.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        });

        timeSlots.push({
          startTime: formattedStartTime,
          endTime: formattedEndTime,
          category,
        });

        currentTime = nextTime;
      }

      return timeSlots;
    };

    const morningSlots = createTimeSlots(
      morningStartTime,
      morningEndTime,
      "morning"
    );

    const eveningSlots = createTimeSlots(
      eveningStartTime,
      eveningEndTime,
      "evening"
    );

    return [...morningSlots, ...eveningSlots];
  }

  const onChange = (newDate) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    newDate.setHours(0, 0, 0, 0);
    if (newDate < currentDate) {
      alert("Selected date is in the past", "warning");
      setCategorizedTimes([]);
      // Handle the case when the selected date is in the past
    } else {
      const dayOfWeek = newDate.toLocaleDateString("en-US", {
        weekday: "long",
      });

      setDate(newDate);
      setSelectedDateModal(newDate);
      const labelToFilter = dayOfWeek;

      let dayssss = data?.data?.appointmentTimes;
      if (dayssss != 0) {
        const filteredArray = dayssss.filter(
          (item) => item.day == labelToFilter
        );

        if (filteredArray != 0) {
          const morningStartTime = filteredArray[0]?.morning?.startTime || "0";
          const morningEndTime = filteredArray[0]?.morning?.endTime || "0";
          const afternoonStartTime =
            filteredArray[0]?.afternoon?.startTime || "0";
          const afternoonEndTime = filteredArray[0]?.afternoon?.endTime || "0";
          const eveningStartTime = filteredArray[0]?.evening?.startTime || "0";
          const eveningEndTime = filteredArray[0]?.evening?.endTime || "0";
          const timeSlots = generateTimeSlots(
            morningStartTime,
            morningEndTime,
            eveningStartTime,
            eveningEndTime
          );

          const currentDate = new Date();
          let chosenDate = new Date(newDate);
          // Check if the chosen date is today

          if (
            chosenDate.getFullYear() === currentDate.getFullYear() &&
            chosenDate.getMonth() === currentDate.getMonth() &&
            chosenDate.getDate() === currentDate.getDate()
          ) {
            // Get current time
            const currentTime = new Date();

            // Loop through the array of time slots
            let filteredTimeSlots = [];

            for (let i = 0; i < timeSlots.length; i++) {
              const endTimeParts = timeSlots[i].endTime.match(
                /(\d+):(\d+)\s*(AM|PM)?/i
              );
              if (!endTimeParts) continue; // Skip if endTime is invalid

              let endTimeHour = parseInt(endTimeParts[1]);
              const endTimeMinute = parseInt(endTimeParts[2]);
              const endTimePeriod = endTimeParts[3]
                ? endTimeParts[3].toUpperCase()
                : null;

              // Adjust hour for PM times
              if (endTimePeriod == "PM" && endTimeHour !== 12) {
                endTimeHour += 12;
              } else if (endTimePeriod == "AM" && endTimeHour === 12) {
                endTimeHour = 0;
              }

              const endTime = new Date();
              endTime.setHours(endTimeHour, endTimeMinute, 0, 0);

              // Compare with current time
              if (endTime > currentTime) {
                filteredTimeSlots.push(timeSlots[i]);
              }
            }

            setCategorizedTimes(filteredTimeSlots);
          } else {
            setCategorizedTimes(timeSlots);
          }
        } else {
          setCategorizedTimes([]);
        }
      } else {
      }
    }
  };

  const disablePastDates = ({ date, view }) => {
    // Disable all dates in the past
    if (view === "month") {
      return date < new Date().setHours(0, 0, 0, 0);
    }
  };
  useEffect(() => {
    getDocdetails({ doctorId: doctorId });
  }, []);
  return (
    <div className="modal-booking-body p-1">
      <div className="row"> 
        {/* <div class="appointment-brief">
          <div class="col-md-12 col-lg-6 doctor-name">{"drName"}</div>
        </div> */}
        <div class="col-md-12 col-lg-6"  data-aos="fade-right">
          <div class="book-date">
            <h5>Select Date</h5>
            <Calendar
              onChange={onChange}
              value={date}
              tileDisabled={disablePastDates}
            />
          </div>
        </div>
        <div class="col-md-12 col-lg-6"  data-aos="fade-right">
          <div class="book-date">
            <h5>Select Time (IST)</h5>
            <div className="row">
              {categorizedTimes.length > 0 ? (
                ["morning", "afternoon", "evening"].map((category) => {
                  const categoryTimes = categorizedTimes.filter(
                    (timeSlot) => timeSlot.category === category
                  );

                  return (
                    categoryTimes.length > 0 && (
                      <div className="col-md-12" key={category}>
                        <h6>
                          {category.charAt(0).toUpperCase() + category.slice(1)}
                        </h6>
                        <div className="row">
                          {categoryTimes.map((timeSlot, index) => (
                            <div
                              className="col-lg-6 col-md-6 col-12"
                              key={index}
                            >
                              <div
                                className="book-date-tile"
                                onClick={() => {
                                  handleSelectTime(timeSlot.startTime);
                                }}
                                style={{
                                  cursor: "pointer",
                                  padding: "0px 5px",
                                  height: "58px",
                                  color: "green",
                                }}
                              >
                                {`${timeSlot.startTime} - ${timeSlot.endTime}`}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )
                  );
                })
              ) : (
                <p>No time slots available.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
