import React from "react";
let baseUrlImg = process.env.REACT_APP_IN_USE_LIVE_URL_IMG;

export default function SecondSection({ Api, tb_data4 }) {
  return (
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <img
            src={
              Api.secondImage
                ? `${baseUrlImg}/public/${Api.secondImage}`
                : "https://placehold.co/600x400"
            }
            width="100%"
            alt=""
          />
        </div>
        <div class="col-lg-6">
          <h2 class="a1">{Api.secondTitle}</h2>
          {/* <p class="a4">{Api.secondDescription}</p> */}
          {tb_data4}
        </div>
      </div>
    </div>
  );
}
