import React, { useState, useEffect } from "react";
import "../css/bootstrap.css";
import "../headers.css";
import image2 from "../img/ico-callender.png";
import image3 from "../img/ico-checkup.png";
import image4 from "../img/ico-doctor.png";
import image5 from "../img/breadcrumb-bg.png";
import Header from "../header.js";
import Footer from "../footer.js";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min.js";

import { Toast } from "../Toast/Toast.jsx";
import { usePostAPIJson } from "../../Custom-Hook/usePostAPIJson.jsx";
import ServiceCard from "./ServicePageCard/ServiceCard.jsx";
import CustomModal from "../../Modal/CustomModal.jsx";

 
let baseUrl = process.env.REACT_APP_IN_USE_LIVE_URL
function Servicelist() {
  const [isModalVisible, setModalVisible] = useState(false);
  const [serviceName, setServiceName] = useState();
  const showModal = (serviceName) => {
    setServiceName(serviceName);
    setModalVisible(true);
  };
  const hideModal = () => setModalVisible(false);
  const [toasts, setToasts] = useState([]);

  const addToast = (message, color) => {
    setToasts((prevToasts) => [
      ...prevToasts,
      { id: Date.now(), message, color },
    ]);
  };

  const handleToastClose = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  const currentURL = window.location.href;

  // console.log(categoryname);

  // GET SERVICES BY Catgeory Url API AND RENDER;
  const { categoryname } = useParams();
  let getServiceByCategoryIdURL = `${baseUrl}/getServices-by-category-url`;
  const { data, postData: getServices } = usePostAPIJson(
    getServiceByCategoryIdURL
  );
  useEffect(() => {
    getServices({ url: categoryname });
  }, [categoryname]);

  let getmanageUrls = `${baseUrl}/get-manageUrls`;
  const { data: urlsData, postData: getUrlsAndName } =
    usePostAPIJson(getmanageUrls);

  useEffect(() => {
    getUrlsAndName();
  }, []);

  let tb_data =
    data &&
    data?.data?.map((item, index) => {
      return (
        <ServiceCard
          index={index}
          item={item}
          categoryname={categoryname}
          showModal={showModal}
        ></ServiceCard>
      );
    });

  return (
    <>
      <div>
        <Helmet>
          <title>{data?.serviceCategory?.MetaTitle ?? ""}</title>
          <meta
            name="description"
            content={data?.serviceCategory?.MetaDescription ?? ""}
          />
          <meta
            name="keywords"
            content={data?.serviceCategory?.MetaKeyword ?? ""}
          />
          <link rel="canonical" href={currentURL}></link>
        </Helmet>
      </div>
      <Header />

      <div
        class="page-bg-title"
        style={{ "background-image": `url(${image5})` }}
      >
        <div class="container">
          <h1>{data?.serviceCategory?.Name ?? ""}</h1>
        </div>
      </div>

      <div class="b1">
        <div class="container">
          <div aria-label="breadcrumb">
            <nav
              style={{
                "--bs-breadcrumb-divider":
                  "url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E&#34;);",
              }}
              aria-label="breadcrumb"
            >
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Our Services
                </li>
              </ol>
            </nav>
          </div>
          <div class="col-lg-9 col-md-10">
            <p class="a2">{data?.serviceCategory?.Description ?? ""}</p>
          </div>
        </div>
      </div>

      <div class="bg1">
        <div class="container">
          <div class="row">{tb_data}</div>
        </div>
      </div>

      <div class="bg1">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <Link to="/specialityList" class="home-feature">
                <img
                  src={image2}
                  width="30"
                  height="30"
                  loading="lazy"
                  alt="Book Appointment"
                />{" "}
                Book Appointment
              </Link>
            </div>
            <div class="col-md-4">
              <a
                href={urlsData?.message?.card1url ?? "/"}
                style={{ textDecoration: "none" }}
              >
                <button class="home-feature">
                  <img
                    src={image3}
                    width="30"
                    height="30"
                    loading="lazy"
                    alt="Book Appointment"
                  />{" "}
                  {/* Health Checkup */}
                  {urlsData?.message?.card1Name ?? ""}
                </button>
              </a>
            </div>
            <div class="col-md-4">
              <a
                href={urlsData?.message?.card2url ?? "/"}
                style={{ textDecoration: "none" }}
              >
                <button
                  // onClick={redirectToService}
                  class="home-feature"
                >
                  <img
                    src={image4}
                    width="30"
                    height="30"
                    loading="lazy"
                    alt="Book Appointment"
                  />{" "}
                  {urlsData?.message?.card2Name ?? ""}
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <div className="toast-container position-fixed bottom-0 end-0 p-3">
            {toasts.map((toast) => (
              <Toast
                key={toast.id}
                message={toast.message}
                color={toast.color}
                onClose={() => handleToastClose(toast.id)}
              />
            ))}
          </div>
        </div>
      </div>
      <CustomModal
        isOpen={isModalVisible}
        onRequestClose={hideModal}
        page={"serviceBook"}
        serviceName={serviceName}
        addToast={addToast}
      />

      <Footer />
    </>
  );
}
export default Servicelist;
