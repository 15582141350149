import React, { useEffect } from "react";
import { usePostAPIJson } from "../Custom-Hook/usePostAPIJson";
 
let baseUrl = process.env.REACT_APP_IN_USE_LIVE_URL;
let baseUrlImg = process.env.REACT_APP_IN_USE_LIVE_URL_IMG;

export default function ListOfSpeciality({ setSelectSpeciality, setSteps }) {
  let getSpecialityUrl = `${baseUrl}/getSpecialityByUser`;
  const { data, postData: getSpeciality } = usePostAPIJson(getSpecialityUrl);

  useEffect(() => {
    getSpeciality();
  }, []);

  return (
    <div className="modal-booking-body">
      <div className="row p-1">
        {data?.data?.map((item, index) => {
          return (
            <>
              {" "}
              <div
                data-aos="fade-right"
                className="col-xl-4 col-lg-6 col-md-6 col-12"
                onClick={() => {
                  setSelectSpeciality(item);
                  setSteps(2);
                }}
              >
                <div className="book-service">
                  <div className="book-service-img">
                    <img
                      src={`${baseUrlImg}/public/${item.imagethumbnail}`}
                      width={50}
                      height={50}
                      alt="Speciality"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "https://placehold.co/50x50";
                      }}
                    />
                  </div>
                  <div className="book-service-content">
                    <h5>{item.Name}</h5>
                    <p></p>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}
