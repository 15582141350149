import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
let baseUrlImg = process.env.REACT_APP_IN_USE_LIVE_URL_IMG;

export default function DoctorCardSection({
  index,
  item,
  redirecttodoctordetail,
}) {
  return (
    <div key={index}>
      <div class="home_our_doctors_container">
        <div class="row home_doctor_detail">
          <div class="col-12 col-lg-4 center">
            <img
              src={`${baseUrlImg}/public/${item?.Image}`}
              width="auto"
              height="100"
              style={{width:"auto"}}
              loading="lazy"
              alt="Doctor Profile Images"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "https://placehold.co/80x80";
              }}
            />
          </div>
          <div class="col-12 col-lg-8 home_doctor_detail_content">
            <Link
              class="doctor-name"
              to={`/doctordetail/${item?.url}`}
              style={{ cursor: "pointer" }}
            >
              {item.name}
            </Link>
            <p>
              {item.Speciality}
              <span>{item.Experience}+ years of experience</span>
            </p>
          </div>
        </div>
        <p>{item.Description}</p>
        <div class="row justify-content-md-center">
          <div class="col col-lg-10 text-center">
            <Link to={`/doctordetail/${item?.url}`}>
              <button
                // onClick={redirecttodoctordetail.bind(this, item)}
                class="btn btn-secondary btn-special-cta"
              >
                Book Appointment
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
