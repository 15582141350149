import React, { useEffect, useRef, useState } from "react";
import { usePostAPIJson } from "../Custom-Hook/usePostAPIJson";
let baseUrl = process.env.REACT_APP_IN_USE_LIVE_URL;
let style = `.otp-container {
  margin:10% auto;
  text-align: center;
}
.otp-container h3{
  color: #3e3b3b;
  display: block;
  font-family: Poppins, sans-serif;
  font-size: 1.4rem;
  font-weight:500;
}

.digit-input {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.digit-input input {
  width: 40px;
  height: 40px;
  margin: 2rem 1rem;
  text-align: center;
  font-size: 1.2em;
}

.verify-button,
.resend-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 6pm 18px;
  margin: 1rem;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1em;
}

.verify-button:hover,
.resend-button:hover {
  background-color: #0056b3;
}

.resend-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}`;

//  [serviceName ,serviceBookDate]  only came from service book page and the api content also change for booking service
// Add patientDetails are same

export default function SendOTPPage({
  mobileNumber,
  setSteps,
  patientDetails,
  selectedDateModal,
  selectedTimeModal,
  doctorId,
  serviceName,
  serviceBookDate,
}) {
  
  function convertToISOString(dateStr) {
    // Split the date string into day, month, and year components
    const [day, month, year] = dateStr.split('/');
    
    // Create a new Date object using the components
    const dateObj = new Date(`${year}-${month}-${day}`);
    
    // Check if the dateObj is valid
    if (isNaN(dateObj.getTime())) {
      throw new Error("Invalid date format");
    }
  
    // Convert the Date object to an ISO string
    return dateObj.toISOString();
  }
  let sendOtpUrl = `${baseUrl}/sendOtp`;
  const {
    data: sendOtpResponse,
    postData: sendOtp,
    setData: setSendOtpResponse,
  } = usePostAPIJson(sendOtpUrl);

  let verifyOtpUrl = `${baseUrl}/verifyOtp`;
  const {
    data: response,
    postData: verifyOtpApi,
    setData: setVerfyResponse,
  } = usePostAPIJson(verifyOtpUrl);

  const verifyOtp = () => {
    let otps = otp.join("");

    verifyOtpApi({
      mobileNumber: mobileNumber,
      otp: otps,
    });
  };

  // After OTP VERIFIED SUCESS; SEND PATIENT DETAILS AND ADD BOOKSLOT;
  let sendPatientDetailUrl = `${baseUrl}/addPatientdetail`;
  const {
    postData: sendPatientDetailApi,
    data: patientResponse,
    setData: setPatientApiResponse,
  } = usePostAPIJson(sendPatientDetailUrl);

  let addBookSlotlUrl = `${baseUrl}/addBookslot`;
  const { postData: addBookSlotApi } = usePostAPIJson(addBookSlotlUrl);

  // Handel Notification and Send PatientDetails NAd Add book slot when otp verify success
  useEffect(() => {
    if (response && response?.success == 1) {
      alert("otp verified successfully");
      delete patientDetails["check"];
      sendPatientDetailApi(patientDetails);
      setVerfyResponse(null);
      console.log(111);
    }
    if (response && response?.success == 0) {
      alert("otp Not verified successfully");
      setVerfyResponse(null);
    }
    if (sendOtpResponse && sendOtpResponse?.success == 1) {
      alert("OTP send successfully");
      setSendOtpResponse(null);
      setIsOtpSent(true);
    }
  }, [sendOtpResponse, response]);

  useEffect(() => {
    if (patientResponse && patientResponse?.success == 1) {
      const inputDate = selectedDateModal;
      const dateObj = new Date(inputDate);

      // Convert to ISO string
      console.log(patientResponse, "patientResponse");

      // Book SLot For Service Page Modal;
      if (serviceBookDate && serviceName) {
       
        addBookSlotApi({
          date: convertToISOString(serviceBookDate),
          serviceBooking: "1",
          service: serviceName,
          doctorId: doctorId,
          name: patientResponse?.data?.name,
          patientId: patientResponse.data._id,
        });
        setSteps(6);
      } else {
        const isoString = dateObj?.toISOString();
        addBookSlotApi({
          date: isoString,
          doctorBooking: "1",
          doctorId: doctorId,
          name: patientResponse?.data?.name,
          patientId: patientResponse.data._id,
          time: selectedTimeModal,
        });
        setSteps(6);
      }
    }
  }, [patientResponse]);

  const [resendTimer, setResendTimer] = useState(60);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [isOtpSent, setIsOtpSent] = useState(false);

  const handleChange = (index, value) => {
    if (isNaN(value)) return; // Only allow numeric input
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (value && index < otp.length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };
  useEffect(() => {
    sendOtp({ mobileNumber: mobileNumber });
  }, []);

  const resendOtp = () => {
    sendOtp({ mobileNumber: mobileNumber });
  };
  useEffect(() => {
    let timer;

    if (isOtpSent) {
      timer = setTimeout(() => {
        if (resendTimer > 0) {
          setResendTimer(resendTimer - 1);
        }
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [isOtpSent, resendTimer]);
  return (
    <div className="otp-container">
      <style>{style}</style>
      <h3>Enter OTP</h3>
      <p>Please verify your mobile number to proceed.</p>
      <div className="digit-input">
        {otp?.map((digit, index) => (
          <input
            key={index}
            type="text"
            maxLength="1"
            value={digit}
            onChange={(e) => handleChange(index, e.target.value)}
            ref={inputRefs[index]} // Ref for the input element
            data-aos="fade-right"
          />
        ))}
      </div>
      <button className="verify-button" onClick={verifyOtp}>
        Verify
      </button>
      {/* Resend OTP button */}
      {resendTimer === 0 ? (
        <button className="resend-button" onClick={resendOtp}>
          Resend OTP
        </button>
      ) : (
        <button className="resend-button" disabled>
          Resend OTP ({resendTimer}s)
        </button>
      )}
    </div>
  );
}
