import "../css/bootstrap.css";
import "../headers.css";
import React, { useState, useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import image6 from "../img/breadcrumb-bg.png";

import parse from "html-react-parser";
import OwlCarousel from "react-owl-carousel";
import Header from "../header.js";
import { Link } from "react-router-dom";
import Footer from "../footer.js";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../owlcarousel/assets/owl.carousel.css";
import "../owlcarousel/assets//owl.theme.default.css";
import "react-calendar/dist/Calendar.css";

import { Toast } from "../Toast/Toast.jsx";
import { useParams } from "react-router-dom/cjs/react-router-dom.min.js";
import { usePostAPIJson } from "../../Custom-Hook/usePostAPIJson.jsx";
import LoadingPage from "../LoadingPage/LoadingPage.jsx";
import CustomModal from "../../Modal/CustomModal.jsx";

let baseUrl = process.env.REACT_APP_IN_USE_LIVE_URL;
let baseUrlImg = process.env.REACT_APP_IN_USE_LIVE_URL_IMG;

function DoctorDetail() {
  const { username } = useParams();
  const history = useHistory();
  const [isModalVisible, setModalVisible] = useState(false);
  const showModal = () => setModalVisible(true);
  const hideModal = () => setModalVisible(false);
  const [drDetail, setDoctorDetail] = useState();
  const redirectodetailss = (item) => {
    let give = decodeURIComponent(item.url);
    const formattedUrl = give.replace(/\s+/g, "-").toLowerCase();
    history.push({
      pathname: `/doctordetail/${formattedUrl}`,
      state: { item },
    });
  };

  const options = {
    loop: false,
    startPosition: 1,
    center: true,
    responsiveClass: true,
    nav: true,
    items: 3,
    margin: 20,
    autoplay: false,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1170: {
        items: 3,
        nav: true,
      },
    },
  };

  const [toasts, setToasts] = useState([]);

  const addToast = (message, color) => {
    setToasts((prevToasts) => [
      ...prevToasts,
      { id: Date.now(), message, color },
    ]);
  };

  const handleToastClose = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  const currentURL = window.location.href;

  let getServiceByURL = `${baseUrl}/get-doctor-by-url`;
  const { data, postData: getDoc, loading } = usePostAPIJson(getServiceByURL);

  let getUtherDoctorUrl = `${baseUrl}/getDoctorListByUser`;
  const { data: otherDocData, postData: getOtherDoc } =
    usePostAPIJson(getUtherDoctorUrl);
  useEffect(() => {
    window.scroll(0, 0);
    getDoc({ url: username });
    getOtherDoc();
  }, [username]);
  // console.log(otherDocData, "data");

  return (
    <>
      <div>
        <Helmet>
          <title>{data?.data?.metaTitle ?? " "}</title>
          <meta
            name="description"
            content={data?.data?.metaDescription ?? " "}
          />
          <meta name="keywords" content={data?.data?.metaKeyword ?? " "} />
          <link rel="canonical" href={currentURL ? currentURL : null}></link>
        </Helmet>
      </div>
      <Header />

      <div
        class="page-bg-title"
        style={{ "background-image": `url(${image6})` }}
      >
        <div class="container">
          <h1>Our Doctors</h1>
        </div>
      </div>

      {loading ? (
        ""
      ) : (
        <div class="b1">
          <div class="container">
            <div aria-label="breadcrumb">
              <nav
                style={{
                  "--bs-breadcrumb-divider":
                    " url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E&#34;);",
                }}
              >
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li class="breadcrumb-item">
                    {(() => {
                      const speciality = data?.data?.Speciality[0] ?? "";

                      const formattedUrl = speciality
                        .replace(/\s+/g, "-")
                        .toLowerCase();
                      return (
                        <Link to={`/specialitydetail/${data?.spec?.url}`}>
                          {speciality}
                        </Link>
                      );
                    })()}
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {data?.data?.name}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      )}

      {loading ? (
        <LoadingPage></LoadingPage>
      ) : (
        <div class="bg1">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-4">
                <img
                  src={
                    data && data.data
                      ? `${baseUrlImg}/public/${data?.data?.Image}`
                      : null
                  }
                  loading="lazy"
                  class="img-fluid img-thumbnail rounded"
                  alt="Doctor Images"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "https://placehold.co/100x100";
                  }}
                />
              </div>
              <div class="col-lg-9 col-md-8 speciality_doctor_detail_content">
                <h1>{data?.data?.name ?? ""}</h1>
                <p class="a4">
                  {data?.data?.Speciality ?? ""} |&nbsp;
                  <span>
                    {data?.data?.Experience ?? ""} + years of experience
                  </span>
                </p>
                <p class="a2">{data?.data?.Description}</p>
                <button
                  class="btn btn-secondary btn-special-cta"
                  onClick={() => {
                    showModal();
                    setDoctorDetail({ ...data.data });
                  }}
                >
                  Book Appointment
                </button>
              </div>
            </div>
            <div class="col-lg-10">
              {parse(data?.data?.fulldescription ?? "")}
            </div>
          </div>
        </div>
      )}
      <div class="bg1">
        <div class="container">
          <div class="row">
            <h3 class="a6">Other Doctors</h3>
            {/* <!--Doctor TAB--> */}

            {/* <!--Doctor TAB END-->
              <!--Doctor TAB--> */}
            {/* {tb_data} */}
            {otherDocData?.data?.length > 0 ? (
              <OwlCarousel
                id="customers-testimonials1"
                className="owl-theme"
                {...options}
              >
                {otherDocData?.data?.length > 0
                  ? otherDocData?.data?.map((item, index) => {
                      return (
                        <div key={index}>
                          <div class="speciality_our_doctors_container">
                            <div class="row speciality_doctor_detail">
                              <div class="col-4">
                                <img
                                  src={`${baseUrlImg}/public/${item.Image}`}
                                  width="100"
                                  height="100"
                                  alt="Doctor Images"
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src =
                                      "https://placehold.co/100x100";
                                  }}
                                />
                              </div>
                              <div class="col-8 speciality_doctor_detail_content">
                                <a
                                  class="doctor-name"
                                  onClick={redirectodetailss.bind(this, item)}
                                  style={{ cursor: "pointer" }}
                                >
                                  {item.name}
                                </a>
                                <p>
                                  {item.Speciality}
                                  <span>
                                    {item.Experience}+ years of experience
                                  </span>
                                </p>
                              </div>
                            </div>
                            <p class="a3">{item.Description}</p>
                            <div class="row justify-content-md-center">
                              <div class="col col-lg-10 text-center">
                                <button
                                  onClick={redirectodetailss.bind(this, item)}
                                  class="btn btn-secondary btn-special-cta"
                                >
                                  Book Appointment
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </OwlCarousel>
            ) : null}
            {/* <!--Doctor TAB END--> */}
          </div>
        </div>
      </div>

      {/* <div class="bg1">
        <div class="container">
          <div class="col-lg-10">
            <h2 class="a6">Frequently Asked Questions</h2>
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Accordion Item #1
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body a2">
                    <strong>This is the first item's accordion body.</strong> It
                    is shown by default, until the collapse plugin adds the
                    appropriate classes that we use to style each element. These
                    classes control the overall appearance, as well as the
                    showing and hiding via CSS transitions. You can modify any
                    of this with custom CSS or overriding our default variables.
                    It's also worth noting that just about any HTML can go
                    within the <code>.accordion-body</code>, though the
                    transition does limit overflow.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Accordion Item #2
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body a2">
                    <strong>This is the second item's accordion body.</strong>{" "}
                    It is hidden by default, until the collapse plugin adds the
                    appropriate classes that we use to style each element. These
                    classes control the overall appearance, as well as the
                    showing and hiding via CSS transitions. You can modify any
                    of this with custom CSS or overriding our default variables.
                    It's also worth noting that just about any HTML can go
                    within the <code>.accordion-body</code>, though the
                    transition does limit overflow.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Accordion Item #3
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body a2">
                    <strong>This is the third item's accordion body.</strong> It
                    is hidden by default, until the collapse plugin adds the
                    appropriate classes that we use to style each element. These
                    classes control the overall appearance, as well as the
                    showing and hiding via CSS transitions. You can modify any
                    of this with custom CSS or overriding our default variables.
                    It's also worth noting that just about any HTML can go
                    within the <code>.accordion-body</code>, though the
                    transition does limit overflow.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="container mt-5">
        <div className="toast-container position-fixed bottom-0 end-0 p-3">
          {toasts.map((toast) => (
            <Toast
              key={toast.id}
              message={toast.message}
              color={toast.color}
              onClose={() => handleToastClose(toast.id)}
            />
          ))}
        </div>
      </div>
      <CustomModal
        isOpen={isModalVisible}
        onRequestClose={hideModal}
        page="doctorPage"
        drDetail={drDetail}
        addToast={addToast}
      />

      <Footer />

      {/* <!-- Owl Stylesheets -->
        <link rel="stylesheet" href="owlcarousel/assets/owl.carousel.min.css">
        <link rel="stylesheet" href="owlcarousel/assets/owl.theme.default.min.css">
        
        <script src="https://code.jquery.com/jquery-3.5.1.min.js"></script> */}

      <script src="js/bootstrap.bundle.min.js"></script>
    </>
  );
}

export default DoctorDetail;
