import React, { useEffect } from "react";
import { usePostAPIJson } from "../Custom-Hook/usePostAPIJson";
let baseUrl = process.env.REACT_APP_IN_USE_LIVE_URL;

let baseUrlImg = process.env.REACT_APP_IN_USE_LIVE_URL_IMG;

export default function DoctorListBySpec({
  selectedSpec,
  setSelectDoctor,
  setSteps,
}) {
  let getDocBySPecUrl = `${baseUrl}/getdoctorbyspec`;
  const { data, postData: getDocBySpec } = usePostAPIJson(getDocBySPecUrl);

  useEffect(() => {
    getDocBySpec({ speciality: selectedSpec });
  }, []);

  if (!selectedSpec) {
    return <div>Select Speciality</div>;
  }
  return (
    <div className="modal-booking-body">
      <div className="row">
        {data?.data?.map((item, index) => {
          return (
            <>
              <div
                data-aos="fade-right"
                class="col-xl-3 col-lg-4 col-md-6 col-12"
                key={index}
                onClick={() => {
                  setSelectDoctor(item);
                  setSteps(3);
                }}
              >
                <div class="book-doctor">
                  <div class="book-doctor-img">
                    <img
                      src={`${baseUrlImg}/public/${item.Image}`}
                      width="100"
                      height="100"
                      loading="lazy"
                      alt="Doctor Profile Images"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "https://placehold.co/100x100";
                      }}
                    />
                  </div>
                  <div class="book-doctor-content">
                    <h5>{item.name}</h5>
                    <p>{item.Speciality}</p>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}
