import React, { useState } from "react";
import { Toast } from "../component/Toast/Toast";

let obj = {
  email: "",
  name: "",
  lastName: "",
  note: "",
  phoneNumber: "",
  check: false,
};
export default function PatientDetailFormModal({
  setPatientDetails,
  setSteps,
  selectStep,addToast
}) {
  const [form, setForm] = useState(obj);
  
  const handelChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const Star = () => {
    return <span style={{ color: "red" }}>*</span>;
  };
  const validateObject = (obj) => {
    const phoneNumberPattern = /^[0-9]{10}$/;
  
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
  
        // Check for empty or whitespace-only strings
        if (typeof value === "string" && value.trim() === "") {
          addToast(key + " is Empty", 'warning');
          return false;
        }
  
        // Check for boolean false values
        if (value === false) {
          addToast(key + " is Empty", 'warning');
          return false;
        }
  
        // Check for phone number validation
        if (key === "phoneNumber" && !phoneNumberPattern.test(value)) {
          addToast("Phone number must be exactly 10 digits and contain only numbers", 'warning');
          return false;
        }

        if (key === "note") {
          const wordCount = value.trim().split(/\s+/).length;
          if (wordCount > 50) {
            addToast("Note cannot exceed 50 words", 'warning');
            return false;
          }
        }
      }
    }
    return true;
  };
  

  const onClickNextSummaryBtn = () => {
    const ans = validateObject(form);
    if (!ans) {
      return;
    }
    setPatientDetails(form);
    if (selectStep) {
      setSteps(selectStep);
    } else {
      setSteps(5);
    }
  };
 
  return (
    <>
      <form class="row g-3 p-1 border mt-2" data-aos="fade-right">
        <div class="col-md-6">
          <label for="inputEmail4" class="form-label">
            First Name <Star></Star>
          </label>
          <input
            type="text"
            class="form-control"
            id="inputEmail4"
            value={form.name}
            name={"name"}
            onChange={handelChange}
          />
        </div>
        <div class="col-md-6">
          <label for="inputPassword4" class="form-label">
            Last Name <Star></Star>
          </label>
          <input
            type="text"
            class="form-control"
            id="inputPassword4"
            value={form.lastName}
            name="lastName"
            onChange={handelChange}
          />
        </div>
        <div class="col-12">
          <label for="inputAddress" class="form-label">
            Email <Star></Star>
          </label>
          <input
            type="email"
            class="form-control"
            id="inputAddress"
            placeholder="Email"
            value={form.email}
            name="email"
            onChange={handelChange}
          />
        </div>
        <div class="col-12">
          <label for="inputAddress2" class="form-label">
            Contact Number <Star></Star>
          </label>
          <input
            type="text"
            class="form-control"
            id="inputAddress2"
            placeholder="Contact Number"
            value={form.phoneNumber}
            name="phoneNumber"
            onChange={handelChange}
          />
        </div>
        <div class="col-12">
          <label for="exampleFormControlTextarea1" class="form-label">
            Note <Star></Star>
          </label>
          <textarea
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
            value={form.note}
            name="note"
            onChange={handelChange}
          ></textarea>
        </div>
        <div class="col-12">
          <div class="form-check">
            <input
              onClick={() => {
                setForm({ ...form, check: true });
              }}
              class="form-check-input"
              type="checkbox"
              id="gridCheck"
            />
            <label class="form-check-label" for="gridCheck">
              By selecting you agree to our Terms & Condition and Privacy
              Policy.
            </label>
          </div>
        </div>
        <div class="col-12">
          <h2
            // type="submit"
            class="btn btn-primary"
            onClick={() => {
              onClickNextSummaryBtn();
            }}
          >
            Next Summary
          </h2>
        </div>
         
      </form>
    </>
  );
}
