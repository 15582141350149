import React from "react";
let baseUrlImg = process.env.REACT_APP_IN_USE_LIVE_URL_IMG;

export default function SpecialityCard({
  index,
  item,
  redirecttospecialitydetail,
}) {
  return (
    <div
      class="col-6 col-sm-6 col-lg-4"
      key={index}
      onClick={redirecttospecialitydetail.bind(this, item)}
    >
      <a className="speciality_tab1">
        <img
          src={`${baseUrlImg}/public/${item?.imagethumbnail}`}
          width="50"
          height="50"
          loading="lazy"
          alt="Speciality Imageg"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "https://placehold.co/50x50";
          }}
        />
        <span>{item.Name}</span>
      </a>
    </div>
  );
}
