import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
let baseUrlImg = process.env.REACT_APP_IN_USE_LIVE_URL_IMG;

export default function ServiceCard({ index, item, categoryname, showModal }) {
  return (
    <div class="col-sm-6 col-lg-6 col-xl-4" key={index}>
      <div class="speciality_tab3">
        <div class="image">
          <img
            src={`${baseUrlImg}/public/${item?.imagethumbnail}`}
            width="40"
            height="40"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "https://placehold.co/40x40";
            }}
            alt="err"
          />
        </div>
        <div class="title">
          <Link
            to={{
              pathname: `/service/${categoryname}/${item?.url}`,
              state: { serviceDetailPageData: item },
            }}
          >
            {item.Name}
          </Link>
        </div>
        <p>{item.Description}</p>
        <button
          onClick={() => {
            showModal(item.Name);

          }}
        >
          Book Now &#8594;
        </button>
      </div>
    </div>
  );
}
