import moment from "moment";
import React from "react";

export default function BlogCard({ index, item, redirectoblogdetail }) {
  const originalDate = moment(item.createdAt);
  const formattedDate = originalDate.format("MMMM D, YYYY [at] HH:mm:ss");
  return (
    <div key={index}>
      <div class="home_blog">
        <div
          class="home_blog_image"
          style={{ "background-image": `url(${item.imageThumbnail})` }}
        ></div>
        <div class="home_blog_content">
          <div class="home_blog_content_specs">
            <div class="specs_date">{formattedDate}</div>
          </div>
          <h3>{item.title}</h3>
          <p>{item.body}</p>

          <a onClick={redirectoblogdetail.bind(this, item)}>
            Read More &#8594;
          </a>
        </div>
      </div>
    </div>
  );
}
