import React, { useEffect, useState } from "react";
// import { Modal, Button } from "react-bootstrap";
import Modal from "react-modal";
import ListOfSpeciality from "./ListOfSpeciality";
import DoctorListBySpec from "./DoctorListBySpec";
import DoctorSlotBooking from "./DoctorSlotBooking";
import PatientDetailFormModal from "./PatientDetailFormModal";
import SendOTPPage from "./SendOTPPage";
import BookingSuccessModal from "./BookingSuccessModal";
import SelectServiceDateCalender from "./SelectServiceDateCalender";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    // transform: 'translate(-50%, -50%)',
  },
};
Modal.setAppElement("#root");

export default function CustomModal({
  isOpen,
  onRequestClose,
  page,
  serviceName,
  // when modal came from dr detail page;
  drDetail,addToast
}) {
  const [steps, setSteps] = useState(1);
  const [selectSpeciality, setSelectSpeciality] = useState();
  const [selectDoctor, setSelectDoctor] = useState();
  const [selectedDateModal, setSelectedDateModal] = useState();
  const [selectedTimeModal, setSelectedTimeModal] = useState();
  const [patientDetails, setPatientDetails] = useState();

  // For Service Booking
  const [serviceBookDate, setServiceBookDate] = useState();

  useEffect(() => {
    if (page && page === "serviceBook") {
      setSteps(7);
    } else if (page && page === "doctorPage") {
      setSelectDoctor(drDetail);
      setSteps(3);
    } else {
      setSteps(1);
    }
  }, [isOpen]);

  function extractDateParts(timestampString) {
    const timestamp = new Date(timestampString);
    const day = timestamp.getDate().toString().padStart(2, "0"); // Add leading zero if single digit
    const month = (timestamp.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero if single digit
    const year = timestamp.getFullYear();

    return `${day}/${month}/${year}`;
  }
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="d-flex justify-content-between align-items-center">
        {/* {steps === 6 ? "" : <h4 className="mb-0">Book Appointment</h4>} */}
        {steps === 1 ? (
          <h4 className="mb-0" data-aos="fade-right">
            Book Appointment - Select Speciality
          </h4>
        ) : (
          ""
        )}

        {steps === 2 ? (
          <h4 className="mb-0">
            Book Appointment -{" "}
            <span
              // className="text-primary cursor-pointer"
              style={{ cursor: "pointer",color:"#ef7f1a" ,fontSize:"1.2rem" }}
              onClick={() => setSteps(1)}
              data-aos="fade-right"
            >
              {selectSpeciality?.Name}{" "}
            </span>
          </h4>
        ) : (
          ""
        )}

        {steps === 3 ? (
          <h4 className="mb-0">
            Book Appointment -{" "}
            <span
              // className="text-primary cursor-pointer"
              style={{ cursor: "pointer" ,color:"#ef7f1a",fontSize:"1.2rem"}}
              onClick={() => setSteps(1)}
            >
              {selectSpeciality?.Name}{" "}
            </span>{" "}
            -{" "}
            <span
              // className="text-primary cursor-pointer"
              style={{ cursor: "pointer" ,color:"#ef7f1a"  ,fontSize:"1.2rem"}}
              onClick={() => {
                if (page && page === "doctorPage") {
                  return;
                }
                setSteps(2);
              }}
              data-aos="fade-right"
            >
              {selectDoctor?.name}{" "}
            </span>{" "}
          </h4>
        ) : (
          ""
        )}

        {steps === 4 ? (
          <h4 className="mb-0">
            Book Appointment -{" "}
            <span
              // className="text-primary cursor-pointer"
              style={{ cursor: "pointer",color:"#ef7f1a"  ,fontSize:"1.2rem"}}
              onClick={() => setSteps(1)}
            >
              {selectSpeciality?.Name}{" "}
            </span>{" "}
            -{" "}
            <span
              // className="text-primary cursor-pointer"
              style={{ cursor: "pointer",color:"#ef7f1a"  ,fontSize:"1.2rem"}}
              onClick={() => {
                if (page && page === "doctorPage") {
                  return;
                }
                setSteps(2);
              }}
            >
              {selectDoctor?.name}{" "}
            </span>{" "}
            -{" "}
            <span
              // className="text-primary cursor-pointer"
              style={{ cursor: "pointer" ,color:"#ef7f1a"  ,fontSize:"1.2rem"}}
              onClick={() => setSteps(3)}
              data-aos="fade-right"
            >
              {extractDateParts(selectedDateModal)} {selectedTimeModal}
              {}{" "}
            </span>
          </h4>
        ) : (
          ""
        )}

        <button
          type="button"
          className="btn-close"
          onClick={onRequestClose}
          aria-label="Close"
        ></button>
      </div>{" "}
      {steps === 8 ? (
        <h4 className="mb-0" data-aos="fade-right">
          Book Appointment - {serviceName}
        </h4>
      ) : (
        ""
      )}
      {/* IF STEPS=1 Show Speciality List  */}
      {steps === 1 ? (
        <ListOfSpeciality
          setSelectSpeciality={setSelectSpeciality}
          selectSpeciality={selectSpeciality}
          setSteps={setSteps}
        ></ListOfSpeciality>
      ) : (
        ""
      )}
      {steps === 2 && selectSpeciality ? (
        <DoctorListBySpec
          selectedSpec={selectSpeciality?.Name ?? "no"}
          setSteps={setSteps}
          setSelectDoctor={setSelectDoctor}
        ></DoctorListBySpec>
      ) : (
        ""
      )}
      {steps === 3 && selectDoctor ? (
        <DoctorSlotBooking
          doctorId={selectDoctor?._id ?? "no-doctorid"}
          setSteps={setSteps}
          setSelectedTimeModal={setSelectedTimeModal}
          setSelectedDateModal={setSelectedDateModal}
        ></DoctorSlotBooking>
      ) : (
        ""
      )}
      {steps === 4 && selectedDateModal && selectedTimeModal ? (
        <PatientDetailFormModal
          setPatientDetails={setPatientDetails}
          setSteps={setSteps}
          addToast={addToast}
        ></PatientDetailFormModal>
      ) : (
        ""
      )}
      {steps === 5 && patientDetails ? (
        <SendOTPPage
          mobileNumber={patientDetails?.phoneNumber ?? "No-mobile-number"}
          setSteps={setSteps}
          patientDetails={patientDetails}
          selectedDateModal={selectedDateModal}
          selectedTimeModal={selectedTimeModal}
          doctorId={selectDoctor?._id ?? "no-doctorid"}
        ></SendOTPPage>
      ) : (
        ""
      )}
      {/* BookingSuccessModal */}
      {steps === 6 ? <BookingSuccessModal ></BookingSuccessModal> : ""}
      {/* Service Book Steps If modal came from Service Page */}
      {steps === 7 ? (
        <SelectServiceDateCalender
          setServiceBookDate={setServiceBookDate}
          setSteps={setSteps}
          serviceName={serviceName}
        ></SelectServiceDateCalender>
      ) : (
        ""
      )}
      {/* patientForm */}
      {steps === 8 && serviceBookDate ? (
        <PatientDetailFormModal
          setPatientDetails={setPatientDetails}
          setSteps={setSteps}
          selectStep={9}
          addToast={addToast}
        ></PatientDetailFormModal>
      ) : (
        ""
      )}
      {/* stepp 9 */}
      {steps === 9 && patientDetails ? (
        <SendOTPPage
          mobileNumber={patientDetails?.phoneNumber ?? "No-mobile-number"}
          setSteps={setSteps}
          patientDetails={patientDetails}
          // selectedDateModal={selectedDateModal}
          // selectedTimeModal={selectedTimeModal}
          // doctorId={selectDoctor?._id ?? "no-doctorid"}
          serviceName={serviceName}
          serviceBookDate={serviceBookDate}
        ></SendOTPPage>
      ) : (
        ""
      )}
       
    </Modal>
  );
}
