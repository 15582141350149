import React from "react";
import image21 from "../component/img/drawing.svg";

export default function BookingSuccessModal() {
  return (
    <div>
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">
          Book Appointment - Booking Summary
        </h1>
      </div>
      <div class=" modal-booking-body">
        <div class="book-doctor-summary">
          <img src={image21} width="200" />
          <h5>Success</h5>
          <p>
            Your appointment request has been submitted successfully. <br />
            One of our representatives will be in touch with you shortly.
          </p>
        </div>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-text"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={()=>window.location.reload()}
        >
          Close
        </button>
      </div>
    </div>
  );
}
