import React from "react";
import Header from "../header";
import Footer from "../footer";
import imgSuccess from "../img/success.webp";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
export default function SuccessPage() {
  const location = useLocation();
  const date = new URLSearchParams(location.search).get("date");
  const time = new URLSearchParams(location.search).get("time");
  const razorId = new URLSearchParams(location.search).get("razorId");
  const paymentId = new URLSearchParams(location.search).get("paymentId");
  const amount = new URLSearchParams(location.search).get("amount");
  const apid = new URLSearchParams(location.search).get("apid");
  // apid
  return (
    <div>
      <Header />
      <div className="container text-center">
        <div className="row justify-content-center">
          <div className="col-lg-9 col-md-10" style={{ margin: "5% 0 10% 0" }}>
            <img
              src={imgSuccess}
              width="80%"
              height="auto"
              style={{ maxWidth: "400px", height: "auto" }}
              alt="Success"
            />
            <h1 className="a1">Payment Successful</h1>
            <p className="a2">
              Payment against your booking has been made successful.
            </p>
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-8">
                <dl className="row payment_detail">
                  <dt className="col-sm-3">Booking For</dt>
                  <dd className="col-sm-9">
                    {date
                      ? (() => {
                          const originalDate = moment(date);
                          const formattedDate =
                            originalDate.format("MMMM D, YYYY");
                          return formattedDate + " " + time;
                        })()
                      : ""}
                  </dd>
                  <dt className="col-sm-3">Order ID</dt>
                  <dd className="col-sm-9">{razorId}</dd>
                  <dt className="col-sm-3">Payment ID</dt>
                  <dd className="col-sm-9">{paymentId}</dd>
                  <dt className="col-sm-3">Appointment ID</dt>
                  <dd className="col-sm-9">{apid}</dd>
                  <br />
                  <br />
                  <dt className="col-sm-3">Fee Paid</dt>
                  <dd className="col-sm-9">
                    <span className="a5" style={{ color: "#f07404" }}>
                      {amount}
                    </span>
                  </dd>
                </dl>
              </div>
            </div>
            <Link to="/" className="btn btn-primary btn-special-cta">
              Back to Homepage
            </Link>
            <Link to="/contactUs" className="btn btn-secondary btn-special-cta">
              Contact Us
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
