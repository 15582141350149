import React, { useEffect } from "react";
import "../css/bootstrap.css";
import image2 from "../img/breadcrumb-bg.png";
import "../headers.css";
import Header from "../header.js";
import Footer from "../footer.js";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import moment from "moment";
import { useParams } from "react-router-dom/cjs/react-router-dom.min.js";
import { usePostAPIJson } from "../../Custom-Hook/usePostAPIJson.jsx";
let baseUrl = process.env.REACT_APP_IN_USE_LIVE_URL;

function Blogdetail() {
  const { blogurl } = useParams();

  let blogUrl = `${baseUrl}/get-blog-by-url`;
  const { data: blogDetail, postData: getBlogByUrl } = usePostAPIJson(blogUrl);

  useEffect(() => {
    getBlogByUrl({ url: blogurl });
    window.scrollTo(0, 0);
  }, []);
  const originalDate = moment(blogDetail?.data?.createdAt);
  const formattedDate = originalDate.format("MMMM D, YYYY [at] HH:mm:ss");
  const currentURL = window.location.href;
  return (
    <>
      <div>
        <Helmet>
          <title>{blogDetail?.data?.metaTitle ?? " "}</title>
          <meta
            name="description"
            content={blogDetail?.data?.metaDescription ?? " "}
          />
          <meta
            name="keywords"
            content={blogDetail?.data?.metaKeyword ?? " "}
          />
          <link rel="canonical" href={currentURL}></link>
        </Helmet>
      </div>
      <Header />

      <div
        class="page-bg-title"
        style={{ "background-image": `url(${image2})` }}
      >
        <div class="container">
          <h1>Latest Blog</h1>
        </div>
      </div>

      <div class="b1">
        <div class="container">
          <div aria-label="breadcrumb">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li class="breadcrumb-item">
                  <Link to="/bloglist">Latest Blog</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  {blogDetail?.data?.title ?? ""}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div class="bg1">
        <div class="container">
          <div class="row">
            <div class="col-md-9">
              <div class="blogs_card_main">
                <div class="blog_intro_container">
                  <div class="blog_intro_title">
                    {blogDetail?.data?.title ?? ""}
                    <small>{formattedDate}</small>
                  </div>
                  <img
                    src={blogDetail?.data?.imageBanner ?? ""}
                    width="100%"
                    loading="lazy"
                    alt={blogDetail?.data?.title ?? ""}
                  />
                  <p class="blog_intro_content">
                    {parse(blogDetail?.data?.description ?? "")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <script src="js/bootstrap.bundle.min.js"></script>
    </>
  );
}

export default Blogdetail;
