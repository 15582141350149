import React, { useState } from "react";
import Calendar from "react-calendar";

export default function SelectServiceDateCalender({
  setSteps,
  setServiceBookDate,
  serviceName,
}) {
  const [selectedDate, setSelectedDate] = useState();

  // Function to handle date change
  const handleDateChange = (date) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set time to midnight to compare only dates

    // Check if selected date is not in the past
    if (date >= currentDate) {
      setSelectedDate(date);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      setSelectedDate(`${day}/${month}/${year}`);
      setServiceBookDate(`${day}/${month}/${year}`);
      setSteps(8);
    }
  };

  return (
    <div>
      {serviceName}
      <Calendar
        onChange={handleDateChange}
        value={selectedDate}
        minDate={new Date()} // Prevent selecting past dates
      />
    </div>
  );
}
