import Header from "./header.js";
import Footer from "./footer.js";
import image1 from "./img/breadcrumb-bg.png";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./css/bootstrap.css";
import "./headers.css";
import { useHistory } from "react-router-dom";


let baseUrl = process.env.REACT_APP_IN_USE_LIVE_URL;

function Careers() {
  let history = useHistory();
  const [Api, setApi] = useState("");

  const redirecttojobdetail = (item) => {
    let give = decodeURIComponent(item.Title);
    history.push({
      pathname: `/careers/${give}`,
      state: { item },
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    callApi1();
  }, []);

  const callApi1 = () => {
    axios
      .post(`${baseUrl}/getCareerbyuser`)
      .then((res) => {
        setApi(res.data.data);
      });
  };

  const currentURL = window.location.href;

  let tb_data = Api
    ? Api.map((item, index) => {
        return (
          <div class="col-sm-12 col-md-6" key={index}>
            <div class="speciality_tab3">
              <div class="title">{item.Title}</div>
              <p>{item.ShortDescription}</p>
              <button onClick={redirecttojobdetail.bind(this, item)}>
                Apply Now &#8594;
              </button>
            </div>
          </div>
        );
      })
    : null;
  return (
    <>
      <div>
        <Helmet>
          <title>{Api ? Api[0].metaTitle : " "}</title>
          <meta
            name="description"
            content={Api ? Api[0].metaDescription : " "}
          />
          <meta name="keywords" content={Api ? Api[0].metaKeyword : " "} />
          <link rel="canonical" href={currentURL}></link>
        </Helmet>
      </div>
      <Header />

      <div
        class="page-bg-title"
        style={{ "background-image": `url(${image1})` }}
      >
        <div class="container">
          <h1>Careers</h1>
        </div>
      </div>

      <div class="b1">
        <div class="container">
          <div aria-label="breadcrumb">
            <nav
              style={{
                "--bs-breadcrumb-divider":
                  "url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E&#34;);",
              }}
              aria-label="breadcrumb"
            >
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Careers
                </li>
              </ol>
            </nav>
          </div>
          <div class="col-lg-9 col-md-10">
            <p class="a2">
              We are committed to building a team that represents the diverse
              communities we serve, and we believe that our differences make us
              stronger. If you are looking for a rewarding career where you can
              make a meaningful impact, we encourage you to explore our current
              openings and consider joining our team.
            </p>
          </div>
        </div>
      </div>

      <div class="bg1">
        <div class="container">
          <div class="row">{tb_data}</div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Careers;
