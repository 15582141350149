import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useHistory } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
// CSS IMPORT
import "../css/bootstrap.css";
import "../headers.css";
import "react-calendar/dist/Calendar.css";
import "../owlcarousel/assets/owl.carousel.css";
import "../owlcarousel/assets/owl.theme.default.css";

// IMAGE IMPORT
import internalLink from "../img/internal-link.png";
// COMPONENT IMPORT
import { Toast } from "../Toast/Toast.jsx";
import CustomModal from "../../Modal/CustomModal.jsx";
import { usePostAPIJson } from "../../Custom-Hook/usePostAPIJson.jsx";
import Header from "../header.js";
import Footer from "../footer.js";
import BlogCard from "./HomePageCard/BlogCard.jsx";
import SpecialityCard from "./HomePageCard/SpecialityCard.jsx";
import ReportCardSection from "./HomePageCard/ReportCardSection.jsx";
import ThreeCard from "./HomePageCard/ThreeCard.jsx";
import DoctorCardSection from "./HomePageCard/DoctorCardSection.jsx";
import FadeCarousel from "./FadeCarousel.jsx";
let baseUrl = process.env.REACT_APP_IN_USE_LIVE_URL;
let baseUrlImg = process.env.REACT_APP_IN_USE_LIVE_URL_IMG;

function Homepage() {
  let history = useHistory();
  const [Api1, setApi1] = useState();
  const [isModalVisible, setModalVisible] = useState(false);
  const showModal = () => setModalVisible(true);
  const hideModal = () => setModalVisible(false);
  const [points, setPoints] = useState();
  const [input, setInput] = useState([]);
  const [toasts, setToasts] = useState([]);

  const addToast = (message, color) => {
    setToasts((prevToasts) => [
      ...prevToasts,
      { id: Date.now(), message, color },
    ]);
  };

  const handleToastClose = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  const redirectoblogdetail = (item) => {
    let give = decodeURIComponent(item.url);
    const formattedUrl = give.replace(/\s+/g, "-").toLowerCase();
    history.push({
      pathname: `/blogdetail/${formattedUrl}`,
      state: { item },
    });
  };
  const redirectodetails = (item) => {
    if (item.doc) {
      let give = decodeURIComponent(item.url);
      const formattedUrl = give.replace(/\s+/g, "-").toLowerCase();
      history.push({
        pathname: `/doctordetail/${formattedUrl}`,
        state: { item },
      });
    } else if (item.spec) {
      let give = decodeURIComponent(item.Name);
      const formattedUrl = give.replace(/\s+/g, "-").toLowerCase();
      history.push({
        pathname: `/specialitydetail/${formattedUrl}`,
        state: { item },
      });
    }
  };

  let getBlogUrl = `${baseUrl}/getblogs-Main`;
  const { data: blog, postData: getBlog } = usePostAPIJson(getBlogUrl);

  let getSpecialityUrl = `${baseUrl}/getSpecilaitylistforhomepage`;
  const { data: specialitydetail, postData: getSpeciality } =
    usePostAPIJson(getSpecialityUrl);

  let getDoctorUrl = `${baseUrl}/getDoctorlistforhomepage`;
  const { data: doctordetail, postData: getDoctor } =
    usePostAPIJson(getDoctorUrl);

  let getSearchBarUrl = `${baseUrl}/getSearchlist`;
  const {
    data: inputData,
    setData: setInputData,
    postData: getSearchBar,
  } = usePostAPIJson(getSearchBarUrl);

  useEffect(() => {
    if (inputData && inputData.success == 1) {
      setInput(inputData?.data);
      setInputData(null);
    }
  }, [inputData]);

  let tb_data15 = Api1?.points
    ? Api1.points.map((item, index) => {
        return (
          <ul class="list-unstyled" key={index}>
            <li class="featured_pointers1">
              <span></span> {item}
            </li>
          </ul>
        );
      })
    : null;

  const optionsBlog = {
    loop: false,
    center: true,
    responsiveClass: true,
    nav: true,
    startPosition: 1,
    items: 1,
    margin: 20,
    autoplay: true,
    dots: false,
    autoplayTimeout: 10000,
    smartSpeed: 2500,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1170: {
        items: 3,
        nav: true,
      },
    },
  };
  const optionsfordoctor = {
    loop: true,
    center: true,
    responsiveClass: true,
    nav: true,
    startPosition: 1,
    items: doctordetail?.data?.length || 3,
    margin: 20,
    autoplay: true,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1170: {
        items: 3,
        nav: true,
      },
    },
  };

  const currentURL = window.location.href;

  useEffect(() => {
    window.scrollTo(0, 0);
    callApi();
    getDoctor();
    getBlog();
    getSpeciality();
    // getSearchBar();
  }, []);

  const callApi = () => {
    axios.post(`${baseUrl}/getHomepageByUser/`).then((res) => {
      if (res.data.success == "1") {
        setApi1((prevData) => ({ ...prevData, ...res.data.data }));
        setPoints(res.data.data.points);
      }
    });
  };

  let id;
  const handlechange = (value) => {
    if (!value || value === "") {
      setInput([]);
    }

    if (id) {
      clearTimeout(id);
    }
    id = setTimeout(() => {
      if (value.length < 2) {
        addToast("Please enter at least 2 characters to search", "danger");
        return;
      }
      if (value) {
        getSearchBar({ name: value });
      } else {
        setInput();
      }
    }, 1000);
  };

  const redirecttospecialitydetail = (item) => {
    let give = decodeURIComponent(item.Name);
    const formattedUrl = give.replace(/\s+/g, "-").toLowerCase();
    history.push({
      pathname: `/specialitydetail/${item.url}`,
      state: { item },
    });
  };

  const redirecttodoctordetail = (item) => {
    let give = decodeURIComponent(item.url);
    const formattedUrl = give.replace(/\s+/g, "-").toLowerCase();

    history.push({
      pathname: `/doctordetail/${formattedUrl}`,
      state: { item },
    });
  };

  let tb_data = specialitydetail?.data?.map((item, index) => {
    return (
      <SpecialityCard
        item={item}
        index={index}
        redirecttospecialitydetail={redirecttospecialitydetail}
      ></SpecialityCard>
    );
  });

  let tb_data10 = blog
    ? blog?.blogs?.map((item, index) => {
        return (
          <BlogCard
            index={index}
            item={item}
            redirectoblogdetail={redirectoblogdetail}
          ></BlogCard>
        );
      })
    : null;

  let tb_data1 = doctordetail?.data?.map((item, index) => {
    return (
      <DoctorCardSection
        index={index}
        item={item}
        redirecttodoctordetail={redirecttodoctordetail}
      ></DoctorCardSection>
    );
  });

  return (
    <>
      <div>
        <Helmet>
          <title>{Api1 ? Api1.MetaTitle : " "}</title>
          <meta
            name="description"
            content={Api1 ? Api1.MetaDescription : " "}
          />
          <meta name="keywords" content={Api1 ? Api1.Metakeyword : " "} />
          <link rel="canonical" href={currentURL}></link>
        </Helmet>
      </div>
      <Header />

      {Api1?.homePageCrausel ? (
        <FadeCarousel Api1={Api1} baseUrlImg={baseUrlImg} />
      ) : (
        <div style={{ minHeight: "50vh", content: "''" }}> </div>
      )}

      <div class="container">
        <div class="main_canvas_search">
          <div class="row" style={{ width: "100%" }}>
            <div class="col-12 col-md-8 col-lg-9 col-xl-10">
              <input
                class="form-control form-control-lg"
                type="text"
                onChange={(e) => {
                  handlechange(e.target.value);
                }}
                placeholder="Search for Doctors & Specialities"
                aria-label="Search for Doctors & Specialities"
              />
            </div>
            <div class="col-12 col-md-4 col-lg-3 col-xl-2">
              <a href="#" class="btn btn-secondary">
                Search
              </a>
            </div>
            <div class="search-results">
              {input
                ? input.map((item, index) => (
                    <div
                      onClick={redirectodetails.bind(this, item)}
                      key={index}
                      className="d-flex align-items-center mb-2"
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={internalLink}
                        alt="logo"
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "8px",
                        }}
                        loading="lazy"
                      />{" "}
                      {item.name ? item.name : item.Name}{" "}
                    </div>
                  ))
                : ""}
            </div>
          </div>
        </div>
      </div>
      {/* Three Card Section */}
      <div class="bg1">
        <ThreeCard showModal={showModal}></ThreeCard>
      </div>

      {/* Speciality Sections */}
      <div class="bg1">
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col col-lg-8">
              <h2 class="a1 text-center">{Api1?.specialityTitle ?? ""}</h2>
              <p class="a2 text-center">{Api1?.specilaityDescription ?? ""}</p>
            </div>
          </div>
          <div class="row bg2">
            <div class="col-lg-5">
              <img
                src={`${baseUrlImg}/public/${Api1?.specialityImages ?? ""}`}
                class="home_speciality_tab_image"
                alt="Vibrant Hospital Speciality"
                loading="lazy"
              />
            </div>
            <div class="col-lg-7">
              <div class="row home_speciality_tab">{tb_data}</div>
              <Link
                to="/specialityList"
                class="btn btn-secondary btn-special-cta"
                style={{ width: "100%", padding: "1rem 3rem !important" }}
              >
                Browse All Speacialities
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* Doctor Section */}
      <div class="home_our_doctors">
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col col-lg-8">
              <h2 class="a1 text-center">{Api1?.doctorTitle ?? ""}</h2>
              <p class="a2 text-center">{Api1?.doctorDescription ?? ""}</p>
            </div>
          </div>

          <div class="row">
            {doctordetail?.data?.length >= 1 ? (
              <OwlCarousel
                id="customers-testimonials1"
                className="owl-theme"
                {...optionsfordoctor}
              >
                {tb_data1}
              </OwlCarousel>
            ) : null}
          </div>
        </div>
      </div>
      {/* ReportCArdSection */}

      <div class="bg1">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <h2 class="a1">{Api1?.Title}</h2>
              <p class="a4">{Api1?.Description}</p>
              {tb_data15}
            </div>
            <div class="col-lg-6">
              <img
                src={`${baseUrlImg}/public/${Api1?.Image}`}
                width="100%"
                alt="d"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="bg1">
        <ReportCardSection
          secondlastitle={Api1?.secondLastSectionTitle ?? ""}
          secondlistdescription={Api1?.secondLastSectionDescription ?? ""}
        ></ReportCardSection>
      </div>
      {/* BLOG SECTION */}
      <div class="bg1">
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col col-lg-8">
              <h2 class="a1 text-center">Our Latest Blogs</h2>
            </div>
          </div>
          <div class="row">
            <OwlCarousel
              id="customers-testimonials1"
              className="owl-theme"
              {...optionsBlog}
            >
              {tb_data10}
            </OwlCarousel>
            <div
              class="col-12 col-md-9 offset-md-3"
              style={{ "text-align": "right", "margin-top": "3rem" }}
            >
              <Link to="/bloglist" class="btn btn-light btn-special-cta">
                View All
              </Link>
            </div>
          </div>
        </div>
        {/* TOAST  */}
        <div className="container mt-5">
          <div className="toast-container position-fixed bottom-0 end-0 p-3">
            {toasts.map((toast) => (
              <Toast
                key={toast.id}
                message={toast.message}
                color={toast.color}
                onClose={() => handleToastClose(toast.id)}
              />
            ))}
          </div>
        </div>
        <CustomModal
          isOpen={isModalVisible}
          onRequestClose={hideModal}
          addToast={addToast}
        />
      </div>
      <Footer />

      <script src="js/bootstrap.bundle.min.js"></script>
    </>
  );
}

export default Homepage;
