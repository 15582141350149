import React from "react";
import image2 from "../../img/ico-iso.png";
import image3 from "../../img/ico-nabh.png";
import image4 from "../../img/ico-247.png";


export default function VHDetailSection({Api}) {
  return (
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <h2 class="a1">
            <span>About Us </span>
            {Api.title}
          </h2>
          <div  dangerouslySetInnerHTML={{ __html: Api.description }} class="a2">
             
          </div>
        </div>
        <div class="col-lg-1"></div>
        <div class="col-lg-5">
          <div class="row">
            <div class="col-md-4" style={{ "margin-top": "7rem" }}>
              <div class="home_feature_tab1">
                100
                <span>Beds</span>
              </div>
              <div class="home_feature_tab1">
                100+
                <span>Team of Medical Staff</span>
              </div>
            </div>
            <div class="col-md-4" style={{ "margin-top": "3rem" }}>
              <div class="home_feature_tab1">
                100+
                <span>Diagnosis Everyday</span>
              </div>
              <div class="home_feature_tab1">
                <img src={image2} width="50" height="50" loading="lazy" />
                <span>Feature Five</span>
              </div>
            </div>
            <div class="col-md-4" style={{ "margin-top": "0rem" }}>
              <div class="home_feature_tab1">
                <img src={image3} width="50" height="50" loading="lazy" />
                <span>NABH Accreditation</span>
              </div>
              <div class="home_feature_tab1">
                <img src={image4} width="50" height="50" loading="lazy" />
                <span>24/7 Emergency</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
