import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
let baseUrlImg = process.env.REACT_APP_IN_USE_LIVE_URL_IMG;

export default function CrauselCard({ index, item }) {
  return (
    <div key={index}>
      <div class="speciality_our_doctors_container">
        <div class="row speciality_doctor_detail">
          <div class="col-12 col-lg-4 ">
            <img
              src={`${baseUrlImg}/public/${item.Image}`}
              width="auto"
              height="100"
              style={{width:"auto"}}
              loading="lazy"
              alt="Doctor Profile Imageg"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "https://placehold.co/80x80";
              }}
            />
          </div>
          <div class="col-12 col-lg-8 speciality_doctor_detail_content">
            <h5>{item.name}</h5>
            <p>
              {item.Speciality}
              <span>{item.Experience}+ years of experience</span>
            </p>
          </div>
        </div>
        <p class="a3">{item.Description}</p>
        <div class="row justify-content-md-center">
          <div class="col col-lg-10 text-center">
            <Link
              //   onClick={redirecttodoctordetail.bind(this, item)}
              to={`/doctordetail/${item?.url}`}
              class="btn btn-secondary btn-special-cta"
            >
              Book Appointment
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
