import React, { useEffect } from "react";
import image2 from "../../img/ico-callender.png";
import image3 from "../../img/ico-checkup.png";
import image4 from "../../img/ico-doctor.png";
import { usePostAPIJson } from "../../../Custom-Hook/usePostAPIJson";
 
let baseUrl = process.env.REACT_APP_IN_USE_LIVE_URL;
export default function ThreeCard({ showModal }) {
  let getmanageUrls = `${baseUrl}/get-manageUrls`;
  const { data: urlsData, postData: getUrlsAndName } =
    usePostAPIJson(getmanageUrls);

  useEffect(() => {
    getUrlsAndName();
  }, []);

  return (
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <button onClick={showModal} class="home-feature">
            <img
              src={image2}
              width="30"
              height="30"
              loading="lazy"
              alt="Book Appointment"
            />{" "}
            Book Appointment
          </button>
        </div>
        <div class="col-md-4">
          <a href={urlsData?.message?.card1url ?? "/"} style={{textDecoration:'none'}}>
            <button class="home-feature">
              <img
                src={image3}
                width="30"
                height="30"
                loading="lazy"
                alt="Book Appointment"
              />{" "}
              {/* Health Checkup */}
              {urlsData?.message?.card1Name ?? ""}
            </button>
          </a>
        </div>
        <div class="col-md-4">
          <a href={urlsData?.message?.card2url ?? "/"} style={{textDecoration:'none'}}>
            <button
              // onClick={redirectToService}
              class="home-feature"
            >
              <img
                src={image4}
                width="30"
                height="30"
                loading="lazy"
                alt="Book Appointment"
              />{" "}
              {urlsData?.message?.card2Name ?? ""}
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}
