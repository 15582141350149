import React from "react";
let baseUrlImg = process.env.REACT_APP_IN_USE_LIVE_URL_IMG;

export default function MissionAndVission({ Api }) {
  return (
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <h3 class="a5">{Api.missionTitle}</h3>
          <img
            src={
              Api.MissionImage
                ? `${baseUrlImg}/public/${Api.MissionImage}`
                : "https://placehold.co/300x150"
            }
            width="100%"
            loading="lazy"
            alt=""
          />
          <p class="a2" style={{ margin: "2rem 0" }}>
            {Api.missiondescription}
          </p>
        </div>
        <div class="col-lg-6">
          <h3 class="a5">{Api.vissionTitle}</h3>
          <img
            src={
              Api.vissionImage
                ? `${baseUrlImg}/public/${Api.vissionImage}`
                : "https://placehold.co/300x150"
            }
            width="100%"
            loading="lazy"
            alt=""
          />
          <p class="a2" style={{ margin: "2rem 0" }}>
            {Api.vissiondescription}
          </p>
        </div>
      </div>
    </div>
  );
}
