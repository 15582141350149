import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import DoctorDetail from "./component/Doctor/doctordetail";
import Doctorlist from "./component/Doctor/doctordetail";

import Specialitylist from "./component/Speciality/specialitylist";
import Specialitydetail from "./component/Speciality/specialitydetail";
import ContactUs from "./component/contactus";
import NewsDetail from "./component/News/newsdetail";
import News from "./component/News/news";
import Terms from "./component/terms";
import Privacypolicy from "./component/privatepolicy";
import Careers from "./component/careers";
import Bloglist from "./component/Blogs/bloglist";
import Jobdetail from "./component/jobdetail";
import SingleServiceDetailPage from "./component/ServicePages/SingleServiceDetailPage";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Homepage from "./component/HomePage/homepage";
import Servicelist from "./component/ServicePages/servicelist";
import AboutUs from "./component/AboutUSPage/aboutus";
import Blogdetail from "./component/Blogs/blog";
import Error from "./component/404Page/Error";
import PaymentPage from "./Payment/PaymentPage";
import SuccessPage from "./component/SuccessPage/SuccessPage";
import FailedPage from "./FailedPage/FailedPage";
import EmptyPage from "./component/EmptyPage";

function App() {
  useEffect(() => {
    AOS.init({
      offset: -9999,
      duration: 800,
      once: false,
      mirror: true,
    });
  }, []);
  return (
    <Router  >
      <div className="App">
        <Switch>
          <Route path="/" exact component={Homepage} />
          {/*  */}
          <Route path="/p/:id" exact component={EmptyPage} />
          <Route path="/doctordetail/:username" component={DoctorDetail} />
          <Route path="/specialityList" component={Specialitylist} />
          <Route path="/doctorList" component={Doctorlist} />
          <Route path="/service/:categoryname" exact component={Servicelist} />
          {/* Single Service Details */}
          <Route
            path="/service/:categoryname/:id"
            component={SingleServiceDetailPage}
          />
          <Route
            path="/specialitydetail/:specialitynames"
            component={Specialitydetail}
          />
          <Route path="/contactUs" component={ContactUs} />

          <Route path="/news" component={News} />
          <Route path="/newsdetail/:newsurl" component={NewsDetail} />
          <Route path="/blogdetail/:blogurl" component={Blogdetail} />
          <Route path="/Terms" component={Terms} />
          <Route path="/Privacy" component={Privacypolicy} />
          <Route path="/about" component={AboutUs} />
          <Route path="/career" component={Careers} />
          <Route path="/bloglist" component={Bloglist} />
          <Route path="/careers/:careers" component={Jobdetail} />
          <Route path="/payment" component={PaymentPage} />
          <Route path="/payment-success" component={SuccessPage} />
          <Route path="/payment-failed" component={FailedPage} />

          
          <Route path="*" exact component={Error} />

        </Switch>
      </div>
    </Router>
  );
}

export default App;
