import React from "react";
import Header from "../component/header";
import Footer from "../component/footer";
import fail from "../component/img/failed.webp";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
export default function FailedPage() {
  return (
    <div>
      <Header />
      <div className="container text-center">
        <div className="row justify-content-center">
          <div className="col-lg-9 col-md-10" style={{ margin: "5% 0 10% 0" }}>
            <img
              src={fail}
              alt="Payment Failed"
              style={{ width: "60%", maxWidth: "400px", height: "auto" }}
            />
            <h1 className="display-4">Payment Failed</h1>
            <p className="lead">Payment against your booking has failed.</p>
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-8">
                <dl className="row text-left">
                  <dt className="col-sm-3">Booking For</dt>
                  <dd className="col-sm-9">2 Aug, 2024 | 02:30 pm</dd>
                  <dt className="col-sm-3">Order ID</dt>
                  <dd className="col-sm-9">System Order Id</dd>
                  <dt className="col-sm-3">Payment Status</dt>
                  <dd className="col-sm-9">Failed</dd>
                  <dt className="col-sm-3">Reason</dt>
                  <dd className="col-sm-9">Reason from Gateway</dd>
                </dl>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <Link to="/" className="btn btn-primary mr-3">
                Back to Homepage
              </Link>
              <Link ml='8' to="/contactUs" className="btn btn-secondary">
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
