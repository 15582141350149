import React, { useEffect, useState } from 'react';
// import '../css/bootstrap.min.css';

export const Toast = ({ message, color, onClose }) => {
    const [show, setShow] = useState(true);
  
    useEffect(() => {
      const timer = setTimeout(() => {
        setShow(false);
        if (onClose) {
          onClose();
        }
      }, 2000);
  
      return () => clearTimeout(timer);
    }, [onClose]);
  
    return (
      <>
        {show && (
          <div className={`toast show align-items-center text-white bg-${color}`} role="alert">
            <div className="d-flex">
              <div className="toast-body">
                {message}
              </div>
              <button
                type="button"
                className="btn-close btn-close-white me-2 m-auto"
                aria-label="Close"
                onClick={() => {
                  setShow(false);
                  if (onClose) {
                    onClose();
                  }
                }}
              ></button>
            </div>
          </div>
        )}
      </>
    );
  };