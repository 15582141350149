import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function EmptyPage() {
  const { id } = useParams();
  const [url, setUrl] = useState(null);
  const timerRef = useRef(null);

  async function extractPath(baseUrl, fullUrl) {
    try {
      const base = new URL(baseUrl);
      const full = new URL(fullUrl, base.origin); // Resolve full URL against base origin

      // Extract the path after the base URL
      return full.href.substring(base.href.length);
    } catch (error) {
      console.error("Error extracting path:", error);
      return null;
    }
  }
  const history = useHistory();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://vibranthospital.com:4000/${id}`
        );
        window.location.href = response.data;
        // console.log(response.data);
        setUrl(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    // Clean up timer if the component unmounts
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [id]);

  return (
    <div class="d-flex justify-content-center align-items-center vh-100">
    <div class="text-center">
      {/* <p>This link will redirect you to the payement of Vibrant Hospital...</p>
      <a href={url} target="_blank" rel="noreferrer" class="btn btn-primary">
        Open URL Now
      </a> */}
    </div>
  </div>
  );
}
