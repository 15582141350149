import React, { useState, useEffect } from "react";
import "./css/bootstrap.css";
import "./headers.css";
import "./privacy.css";
import Header from "./header.js";
import Footer from "./footer.js";
import image6 from "./img/breadcrumb-bg.png";
import { Link } from "react-router-dom";

function Terms() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Header />

      <div
        class="page-bg-title"
        style={{ "background-image": `url(${image6})` }}
      >
        <div class="container">
          <h1>Terms & Conditions</h1>
        </div>
      </div>

      <div class="b1">
        <div class="container">
          <div aria-label="breadcrumb">
            <nav>
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Terms & Conditions
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div class="bg1">
        <div class="container">
          <div class="row">
            <div class="col-md-9">
              {/* <!--Policy Section Start Here--> */}
              <section class="contact-section padding-100">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-10 col-md-12">
                      <div class="about-content-area">
                        <h4 class="a3">Last Update: 18 Jan, 2024</h4>
                        <div class="padding-top-20 padding-bottom-30">
                          <p class="p1">
                            PLEASE READ THIS TERMS AND CONDITIONS CAREFULLY. BY
                            USING THIS PLATFORM YOU AGREE TO BE BOUND BY ALL OF
                            THE TERMS AND CONDITIONS OF THIS AGREEMENT.
                          </p>
                          <p class="p1">
                            These Terms &amp; Conditions (the &quot;
                            <strong>Agreement</strong>&quot;) governs your use
                            of this platform, [Website link] (the &quot;
                            <strong>Platform</strong>&quot;), and{" "}
                            <strong>Vibrant Hospital</strong>
                            <span class="s1">&nbsp;</span>
                            <strong>Private Limited</strong> (&quot;
                            <strong>the Company</strong>&quot;) offer of
                            services on this Platform.
                            <span class="Apple-converted-space">&nbsp;</span>
                          </p>
                          <p class="p1">
                            This Agreement includes, and incorporates by this
                            reference, the policies and guidelines referenced
                            below. The Company reserves the right to change or
                            revise the terms and conditions of this Agreement at
                            any time by posting any changes or a revised
                            Agreement on this Platform. The Company will alert
                            you that changes or revisions have been made by
                            indicating on the top of this Agreement the date it
                            was last revised. The changed or revised Agreement
                            will be effective immediately after it is posted on
                            this Platform. Your use of the Platform following
                            the posting of any such changes or of a revised
                            Agreement will constitute your acceptance of any
                            such changes or revisions. The Company encourages
                            you to review this Agreement whenever you visit the
                            Platform to make sure that you understand the terms
                            and conditions governing use of the Platform. This
                            Agreement does not alter in any way the terms or
                            conditions of any other written agreement you may
                            have with the Company for other services. If you do
                            not agree to this Agreement (including any
                            referenced policies or guidelines), please
                            immediately terminate your use of the Platform. If
                            you would like to print this Agreement, please click
                            the print button on your browser toolbar.
                          </p>
                          <ol class="ol1">
                            <li class="li1">
                              <strong>SERVICES</strong>
                            </li>
                          </ol>
                          <ol class="ol2">
                            <li class="li2">
                              <strong>Terms of Offer</strong>: This Platform
                              offers for certain services (the &quot;
                              <strong>Services</strong>&quot;). By placing a
                              request for a service through this Platform, you
                              agree to the terms set forth in this Agreement.
                              <span class="Apple-converted-space">&nbsp;</span>
                            </li>
                            <li class="li2">
                              <strong>Customer Solicitation:</strong> Unless you
                              notify our third party call center reps or direct
                              the Company sales reps, while they are calling
                              you, of your desire to opt out from further direct
                              company communications and solicitations, you are
                              agreeing to continue to receive further emails and
                              call solicitations the Company and its designated
                              in house or third party call team(s). &nbsp;
                            </li>
                            <li class="li2">
                              <strong>Opt Out Procedure:</strong> We provide 3
                              easy ways to opt out of from future solicitations.
                              <span class="Apple-converted-space">&nbsp;</span>
                              <ol class="ol3">
                                <li class="li2">
                                  You may use the &ldquo;opt out&rdquo; link
                                  found in any email solicitation that you may
                                  receive.
                                  <span class="Apple-converted-space">
                                    &nbsp;
                                  </span>
                                </li>
                                <li class="li2">
                                  You may also choose to opt out, via sending
                                  your email address to: [
                                  <span class="s2">
                                    <strong>hospitalvibrant@gmail.com]</strong>.
                                    <span class="Apple-converted-space">
                                      &nbsp;
                                    </span>
                                  </span>
                                </li>
                              </ol>
                            </li>
                          </ol>
                          <p class="p3">
                            <span class="s3">
                              You may send a written removal request to
                              the&nbsp;
                            </span>
                            Vibrant Hospital, Sector-100, Near W.T.P., Dwarka
                            Expressway, Gurugram, 122006, Haryana,
                            <span class="s2">&nbsp;</span>India
                            <span class="s4">.</span>
                            <span class="s5">
                              <span class="Apple-converted-space">&nbsp;</span>
                            </span>
                          </p>
                          <ol class="ol2">
                            <li class="li2">
                              <strong>Proprietary Right</strong>s: The Company
                              has proprietary rights and trade secrets in the
                              Services they provide. You may not copy,
                              reproduce, resell or redistribute any Product or
                              Service and/or distributed by the Company. The
                              Company also has rights to all trademarks and
                              trade dress and specific layouts of this webpage,
                              including calls to action, text placement, images
                              and other information.
                            </li>
                            <li class="li1">
                              <strong>Tax:</strong> If you purchase any Service,
                              you will be responsible for paying any applicable
                              tax on the said service.
                            </li>
                          </ol>
                          <p class="p4">
                            <br />
                          </p>
                          <ol class="ol1">
                            <li class="li1">
                              <strong>PLATFORM</strong>
                            </li>
                          </ol>
                          <ol class="ol2">
                            <li class="li2">
                              <strong>
                                Content, intellectual property, third party
                                links
                              </strong>
                              : In addition to making Services available, this
                              Platform also offers information and marketing
                              materials. This Platform also offers information,
                              both directly and through indirect links to
                              third-party platforms. The Company does not always
                              create the information offered on this Platform;
                              instead the information is often gathered from
                              other sources. To the extent that the Company does
                              create the content on this Platform, such content
                              is protected by intellectual property laws of
                              India, foreign nations, and international bodies.
                              Unauthorized use of the material may violate
                              copyright, trademark, and/or other laws. You
                              acknowledge that your use of the content on this
                              Platform is for personal, non-commercial use. Any
                              links to third-party platforms are provided solely
                              as a convenience to you. The Company does not
                              endorse the contents on any such third-party
                              platforms. The Company is not responsible for the
                              content of or any damage that may result from your
                              access to or reliance on these third-party
                              platforms. If you link to third-party platforms,
                              you do so at your own risk.&nbsp;
                            </li>
                            <li class="li5">
                              <span class="s6">
                                <strong>
                                  INTERACTION WITH MEDICAL SERVICE PROVIDERS
                                </strong>
                                :
                              </span>
                            </li>
                          </ol>
                          <ul class="ul1">
                            <li class="li5">
                              <span class="s6">
                                While the user requests for an appointment, the
                                hospital will try to ensure a confirmed
                                appointment with a medical service provider
                                (like a doctor or technicians) for a user on the
                                website, but it shall not in any case provide
                                any guarantee for the user of the said
                                appointment .The appointment shall be subject to
                                rescheduling, postponement, or cancelation
                                depending on the availability of the medical
                                provider. The Hospital has no liability if such
                                an appointment is later canceled, postponed, or
                                rescheduled by the medical service provider, or
                                if the medical service provider is not available
                                as per the given appointment time.
                                <span class="Apple-converted-space">
                                  &nbsp;
                                </span>
                              </span>
                            </li>
                          </ul>
                          <p class="p6">
                            <br />
                          </p>
                          <ul class="ul1">
                            <li class="li5">
                              <span class="s6">
                                The hospital shall hold no liability if the user
                                decides to directly book or engage with the
                                medical service provider.
                                <span class="Apple-converted-space">
                                  &nbsp;
                                </span>
                              </span>
                            </li>
                          </ul>
                          <p class="p7">
                            <br />
                          </p>
                          <ol class="ol2">
                            <li class="li2">
                              <strong>Use of Platform</strong>: The Company is
                              not responsible for any damages resulting from use
                              of this platform by anyone. You will not use the
                              Platform for illegal purposes. You will (1) abide
                              by all applicable local, state, national, and
                              international laws and regulations in your use of
                              the Platform (including laws regarding
                              intellectual property), (2) not interfere with or
                              disrupt the use and enjoyment of the Platform by
                              other users, (3) not engage, directly or
                              indirectly, in transmission of &quot;spam&quot;,
                              chain letters, junk mail or any other type of
                              unsolicited communication, and (4) not defame,
                              harass, abuse, or disrupt other users of the
                              Platform.
                              <span class="Apple-converted-space">&nbsp;</span>
                            </li>
                            <li class="li2">
                              <strong>License:</strong>. By using this Platform,
                              you are granted a limited, non-exclusive,
                              non-transferable right to use the content and
                              materials on the Platform in connection with your
                              normal, noncommercial, use of the Platform. You
                              may not copy, reproduce, transmit, distribute, or
                              create derivative works of such content or
                              information without express written authorization
                              from the Company or the applicable third party (if
                              third party content is at issue).
                            </li>
                            <li class="li1">
                              <strong>Posting:</strong> By posting, storing, or
                              transmitting any content on the Platform, you
                              hereby grant the Company a perpetual, worldwide,
                              non-exclusive, royalty-free, assignable, right and
                              license to use, copy, display, perform, create
                              derivative works from, distribute, have
                              distributed, transmit and assign such content in
                              any form, in all media now known or hereinafter
                              created, anywhere in the world. The Company does
                              not have the ability to control the nature of the
                              user-generated content offered through the
                              Platform. You are solely responsible for your
                              interactions with other users of the Platform and
                              any content you post. The Company is not liable
                              for any damage or harm resulting from any posts by
                              or interactions between users. The Company
                              reserves the right, but has no obligation, to
                              monitor interactions between and among users of
                              the Platform and to remove any content the Company
                              deems objectionable, in the Company&apos;s sole
                              discretion.&nbsp;
                            </li>
                          </ol>
                          <ol class="ol1">
                            <li class="li2">
                              <strong>REFUND &amp; CANCELLATION POLICY</strong>
                            </li>
                          </ol>
                          <p class="p8">
                            In case of cancellation or non-confirmation of the
                            appointment by&nbsp;the hospital, the user shall
                            have the following options:
                          </p>
                          <ul class="ul1">
                            <li class="li9">
                              <span class="s6">
                                The user can reschedule the appointment with the
                                specialist doctor.
                              </span>
                            </li>
                            <li class="li5">
                              <span class="s6">
                                The user can put in a request to claim a refund
                                of the advance payment made only if the
                                cancellation is made by the hospital.
                              </span>
                            </li>
                            <li class="li5">
                              <span class="s6">
                                In case the appointment is canceled by the user
                                or a no show then he shall not be entitled to
                                any refund amount.
                              </span>
                            </li>
                            <li class="li5">
                              <span class="s6">
                                The amount shall be refunded in the same account
                                from which the payment has been made
                                within&nbsp;
                              </span>
                              <span class="s9">15 working&nbsp;</span>
                              <span class="s6">days.</span>
                            </li>
                            <li class="li5">
                              <span class="s6">
                                In case the users credit card/debit card/payment
                                account has been accidentally overcharged,
                                please notify&nbsp;the customer service office.
                                <span class="Apple-converted-space">
                                  &nbsp;
                                </span>
                              </span>
                            </li>
                            <li class="li5">
                              <span class="s6">
                                The refund shall be made by e-banking or by
                                demand draft or by other such mode other than
                                cash, depending upon the suitability of the
                                hospital.
                              </span>
                            </li>
                            <li class="li5">
                              <span class="s6">
                                In order to claim refund, the user should
                                possess a valid invoice and receipt number.
                              </span>
                            </li>
                          </ul>
                          <p class="p10">
                            <br />
                          </p>
                          <ol class="ol1">
                            <li class="li5">
                              <span class="s6">
                                <strong>ONLINE PAYMENT ON THE WEBSITE:</strong>
                              </span>
                            </li>
                          </ol>
                          <p class="p11">
                            <span class="s10">
                              <br />
                            </span>
                            The platform provides the user with an option to pay
                            through an online third-party payment gateway for
                            the services and the appointments availed. If you
                            choose to pay online, the transaction shall be
                            administered by the terms and conditions and privacy
                            policy of the third-party payment gateway system and
                            the hospital shall hold any responsibility (a) if
                            any transaction request does not get completed for
                            any reason whatsoever, or (b) in case of any direct
                            or indirect loss occurred to the user due to
                            non-processing of the transaction request, for any
                            reason whatsoever.
                          </p>
                          <ol class="ol1">
                            <li class="li1">
                              <strong>DISCLAIMER OF WARRANTIES</strong>
                            </li>
                          </ol>
                          <p class="p1">
                            YOUR USE OF THIS PLATFORM AND/OR SERVICES ARE AT
                            YOUR SOLE RISK. THE PLATFORM AND SERVICES ARE
                            OFFERED ON AN &quot;AS IS&quot; AND &quot;AS
                            AVAILABLE&quot; BASIS. THE COMPANY EXPRESSLY
                            DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
                            EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
                            IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                            PARTICULAR PURPOSE AND NON-INFRINGEMENT WITH RESPECT
                            TO THE SERVICES OR PLATFORM CONTENT, OR ANY RELIANCE
                            UPON OR USE OF THE PLATFORM CONTENT OR SERVICES.
                            (&quot;SERVICES&quot; INCLUDE TRIAL SERVICES.)
                          </p>
                          <p class="p1">
                            WITHOUT LIMITING THE GENERALITY OF THE FOREGOING,
                            THE COMPANY MAKES NO WARRANTY:
                          </p>
                          <p class="p1">
                            THAT THE INFORMATION PROVIDED ON THIS PLATFORM IS
                            ACCURATE, RELIABLE, COMPLETE, OR TIMELY.
                          </p>
                          <p class="p1">
                            THAT THE LINKS TO THIRD-PARTY PLATFORMS ARE TO
                            INFORMATION THAT IS ACCURATE, RELIABLE, COMPLETE, OR
                            TIMELY.
                          </p>
                          <p class="p1">
                            NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN,
                            OBTAINED BY YOU FROM THIS PLATFORM WILL CREATE ANY
                            WARRANTY NOT EXPRESSLY STATED HEREIN.&nbsp;
                          </p>
                          <p class="p1">
                            AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE
                            OF THE SERVICES OR THAT DEFICIENCY IN SERVICES WILL
                            BE CORRECTED.&nbsp;
                          </p>
                          <p class="p1">
                            REGARDING ANY SERVICES PURCHASED OR OBTAINED THROUGH
                            THE PLATFORM.
                          </p>
                          <p class="p1">
                            SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF
                            CERTAIN WARRANTIES, SO SOME OF THE ABOVE EXCLUSIONS
                            MAY NOT APPLY TO YOU.
                          </p>
                          <p class="p12">
                            <br />
                          </p>
                          <ol class="ol1">
                            <li class="li1">
                              <strong>LIMITATION OF LIABILITY</strong>
                            </li>
                          </ol>
                          <p class="p1">
                            THE COMPANY ENTIRE LIABILITY, AND YOUR EXCLUSIVE
                            REMEDY, IN LAW, IN EQUITY, OR OTHERWISE, WITH
                            RESPECT TO THE PLATFORM CONTENT AND SERVICES AND/OR
                            FOR ANY BREACH OF THIS AGREEMENT IS SOLELY LIMITED
                            TO THE AMOUNT YOU PAID FOR THE SERVICE VIA THE
                            PLATFORM.
                          </p>
                          <p class="p1">
                            THE COMPANY WILL NOT BE LIABLE FOR ANY DIRECT,
                            INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL
                            DAMAGES IN CONNECTION WITH THIS AGREEMENT OR THE
                            SERVICES IN ANY MANNER, INCLUDING LIABILITIES
                            RESULTING FROM (1) THE USE OR THE INABILITY TO USE
                            THE PLATFORM CONTENT OR SERVICES; (2) THE COST OF
                            PROCURING ALTERNATIVE SERVICE OR CONTENT; (3) ANY
                            SERVICES RECEIVED OR OBTAINED OR TRANSACTIONS
                            ENTERED INTO THROUGH THE PLATFORM; OR (4) ANY LOST
                            PROFITS YOU ALLEGE.
                          </p>
                          <p class="p1">
                            SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR
                            EXCLUSION OF LIABILITY FOR INCIDENTAL OR
                            CONSEQUENTIAL DAMAGES SO SOME OF THE ABOVE
                            LIMITATIONS MAY NOT APPLY TO YOU.
                          </p>
                          <p class="p12">
                            <br />
                          </p>
                          <ol class="ol1">
                            <li class="li1">
                              <strong>INDEMNIFICATION</strong>
                            </li>
                          </ol>
                          <p class="p1">
                            You will release, indemnify, defend and hold
                            harmless the Company, and any of its contractors,
                            agents, employees, officers, directors,
                            shareholders, affiliates and assigns from all
                            liabilities, claims, damages, costs and expenses,
                            including reasonable attorneys&apos; fees and
                            expenses, of third parties relating to or arising
                            out of (1) this Agreement or the breach of your
                            warranties, representations and obligations under
                            this Agreement; (2) the Platform content or your use
                            of the Platform content; (3) the Services; (4) any
                            intellectual property or other proprietary right of
                            any person or entity; (5) your violation of any
                            provision of this Agreement; or (6) any information
                            or data you supplied to the Company. When the
                            Company is threatened with suit or sued by a third
                            party, the Company may seek written assurances from
                            you concerning your promise to indemnify the
                            Company; your failure to provide such assurances may
                            be considered by the Company to be a material breach
                            of this Agreement. The Company will have the right
                            to participate in any defense by you of a
                            third-party claim related to your use of any of the
                            Platform content or Services, with counsel of the
                            Company choice at its expense. The Company will
                            reasonably cooperate in any defense by you of a
                            third-party claim at your request and expense. You
                            will have sole responsibility to defend the Company
                            against any claim, but you must receive the Company
                            prior written consent regarding any related
                            settlement. The terms of this provision will survive
                            any termination or cancellation of this Agreement or
                            your use of the Platform or Services.
                          </p>
                          <p class="p12">
                            <br />
                          </p>
                          <ol class="ol1">
                            <li class="li1">
                              <strong>PRIVACY</strong>
                            </li>
                          </ol>
                          <p class="p1">
                            The Company believes strongly in protecting user
                            privacy and providing you with notice of
                            Company&apos;s use of data. Please refer to the
                            Company privacy policy, incorporated by reference
                            herein, which is posted on the Platform.
                          </p>
                          <ol class="ol1">
                            <li class="li1">
                              <strong>AGREEMENT TO BE BOUND</strong>
                            </li>
                          </ol>
                          <p class="p1">
                            By using this Platform or receiving Services, you
                            acknowledge that you have read and agree to be bound
                            by this Agreement and all terms and conditions on
                            this Platform.&nbsp;
                          </p>
                          <p class="p12">
                            <br />
                          </p>
                          <ol class="ol1">
                            <li class="li1">
                              <strong>GENERAL</strong>
                            </li>
                          </ol>
                          <ol class="ol2">
                            <li class="li2">
                              <strong>Force Majeure</strong>: The Company will
                              not be deemed in default hereunder or held
                              responsible for any cessation, interruption or
                              delay in the performance of its obligations
                              hereunder due to earthquake, flood, fire, storm,
                              natural disaster, act of God, war, terrorism,
                              armed conflict, labor strike, lockout, or boycott.
                            </li>
                            <li class="li2">
                              <strong>Cessation of Operation:</strong> The
                              Company may at any time, in its sole discretion
                              and without advance notice to you, cease operation
                              of the Platform and provision of Services.
                            </li>
                            <li class="li2">
                              <strong>Entire Agreement:</strong> This Agreement
                              comprises the entire agreement between you and the
                              Company with respect to the use of the platform
                              and supersedes any prior agreements pertaining to
                              the subject matter contained herein.
                            </li>
                            <li class="li2">
                              <strong>Effect of Waiver</strong>: The failure of
                              the Company to exercise or enforce any right or
                              provision of this Agreement will not constitute a
                              waiver of such right or provision. If any
                              provision of this Agreement is found by a court of
                              competent jurisdiction to be invalid, the parties
                              nevertheless agree that the court should endeavor
                              to give effect to the parties&apos; intentions as
                              reflected in the provision, and the other
                              provisions of this Agreement remain in full force
                              and effect.
                            </li>
                            <li class="li2">
                              <strong>Governing Law and Jurisdiction</strong>:
                              This Platform originates from the [
                              <strong>Haryana</strong>]. This Agreement will be
                              governed by the laws of India without regard to
                              its conflict of law principles to the contrary.
                              Neither you nor the Company will commence or
                              prosecute any suit, proceeding or claim to enforce
                              the provisions of this Agreement, to recover
                              damages for breach of or default of this
                              Agreement, or otherwise arising under or by reason
                              of this Agreement, other than in courts located in
                              [Haryana]. By using this Platform or Services, you
                              consent to the jurisdiction and venue of such
                              courts in connection with any action, suit,
                              proceeding or claim arising under or by reason of
                              this Agreement. You hereby waive any right to
                              trial by jury arising out of this Agreement and
                              any related documents.
                            </li>
                            <li class="li2">
                              <strong>Waiver of Class Action Rights</strong>: BY
                              ENTERING INTO THIS AGREEMENT, YOU HEREBY
                              IRREVOCABLY WAIVE ANY RIGHT YOU MAY HAVE TO JOIN
                              CLAIMS WITH THOSE OF OTHER IN THE FORM OF A CLASS
                              ACTION OR SIMILAR PROCEDURAL DEVICE. ANY CLAIMS
                              ARISING OUT OF, RELATING TO, OR CONNECTION WITH
                              THIS AGREEMENT MUST BE ASSERTED INDIVIDUALLY.
                            </li>
                            <li class="li2">
                              <strong>Termination:</strong> The Company reserves
                              the right to terminate your access to the Platform
                              if it reasonably believes, in its sole discretion,
                              that you have breached any of the terms and
                              conditions of this Agreement. Following
                              termination, you will not be permitted to use the
                              Platform and the Company may, in its sole
                              discretion and without advance notice to you,
                              cancel any outstanding orders for Services. If
                              your access to the Platform is terminated, the
                              Company reserves the right to exercise whatever
                              means it deems necessary to prevent unauthorized
                              access of the Platform. This Agreement will
                              survive indefinitely unless and until the Company
                              chooses, in its sole discretion and without
                              advance to you, to terminate it.
                            </li>
                            <li class="li2">
                              <strong>Domestic Use</strong>: The Company makes
                              no representation that the Platform or Services
                              are appropriate or available for use in locations
                              outside India. Users who access the Platform from
                              outside India do so at their own risk and
                              initiative and must bear all responsibility for
                              compliance with any applicable local laws.
                            </li>
                            <li class="li1">
                              <strong>Assignment</strong>: You may not assign
                              your rights and obligations under this Agreement
                              to anyone. The Company may assign its rights and
                              obligations under this Agreement in its sole
                              discretion and without advance notice to you.
                            </li>
                          </ol>
                          <p class="p2">
                            BY USING THIS PLATFORM OR PURCHASING ANY SERVICE
                            FROM THIS PLATFORM YOU AGREE TO BE BOUND BY ALL OF
                            THE TERMS AND CONDITIONS OF THIS AGREEMENT.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <script src="js/bootstrap.bundle.min.js"></script>
    </>
  );
}

export default Terms;
