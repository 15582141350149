import "../css/bootstrap.css";
import "../headers.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import "react-calendar/dist/Calendar.css";
import image6 from "../img/breadcrumb-bg.png";
import Header from "../header.js";
import Footer from "../footer.js";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../owlcarousel/assets/owl.carousel.css";
import "../owlcarousel/assets//owl.theme.default.css";
import "react-calendar/dist/Calendar.css";

import { Toast } from "../Toast/Toast.jsx";
import CustomModal from "../../Modal/CustomModal.jsx";
let baseUrl = process.env.REACT_APP_IN_USE_LIVE_URL
let baseUrlImg = process.env.REACT_APP_IN_USE_LIVE_URL_IMG;

function Specialitydetail() {
  const [api, setApi] = useState([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const showModal = () => setModalVisible(true);
  const hideModal = () => setModalVisible(false);
  const [drDetail, setDoctorDetail] = useState();
  const [api3, setApi3] = useState({});
  const [state, setstate] = useState();
  const { specialitynames } = useParams();

  const specialityname = specialitynames;

  // window.location.reload();
  useEffect(() => {
    window.scrollTo(0, 0);
    callApi();
    setstate(specialityname);
  }, [specialityname]);

  const callApi = () => {
    let data = {
      speciality: specialityname,
    };
    axios
      .post(
        `${baseUrl}/get-speciality-by-url`,
        data
      )
      .then((res) => {
        setApi(res.data.data);
        setApi3(res.data.spec);
      });
    let data1 = {
      name: specialityname,
    };
  };

  const [toasts, setToasts] = useState([]);

  const addToast = (message, color) => {
    setToasts((prevToasts) => [
      ...prevToasts,
      { id: Date.now(), message, color },
    ]);
  };

  const handleToastClose = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  let tb_data = api?.map((item, index) => {
    return (
      <div class="col-lg-4" key={index}>
        <div class="speciality_our_doctors_container">
          <div class="row speciality_doctor_detail">
            <div class="col-4">
              <img
                src={`${baseUrlImg}/public/${item.Image}`}
                width="100"
                height="100"
                alt="Doctor Images"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "https://placehold.co/100x100";
                }}
              />
            </div>
            <div class="col-8 speciality_doctor_detail_content">
              <Link
                class="doctor-name"
                to={`/doctordetail/${item?.url}`}
                // onClick={redirecttodoctordetail.bind(this, item)}
                style={{ cursor: "pointer" }}
              >
                {item.name}
              </Link>
              <p>
                {item.Title}
                <span>{item.Experience} years of experience</span>
              </p>
            </div>
          </div>
          <p class="a3">{item.Description}</p>
          <div class="row justify-content-md-center">
            <div class="col col-lg-10 text-center">
              <button
                class="btn btn-secondary btn-special-cta"
                onClick={() => {
                  showModal();
                  setDoctorDetail({ ...item });
                }}
              >
                Book Appointment
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  });

  const currentURL = window.location.href;

  return (
    <>
      <div>
        <div>
          <Helmet>
            <title>{api3?.metaTitle}</title>
            <meta name="description" content={api3?.metaDescription} />
            <meta name="keywords" content={api3?.metaKeyword} />
            <link rel="canonical" href={currentURL}></link>
          </Helmet>
        </div>
      </div>
      <Header />
      <div
        class="page-bg-title"
        style={{ "background-image": `url(${image6})` }}
      >
        <div class="container">
          <h1>Our Doctors</h1>
        </div>
      </div>
      <div class="b1">
        <div class="container">
          <div aria-label="breadcrumb">
            <nav
              style={{
                "--bs-breadcrumb-divider":
                  "url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E&#34;);",
              }}
            >
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li class="breadcrumb-item">
                  <Link to="/specialityList">Our Specialities</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  {specialityname}
                </li>
              </ol>
            </nav>
          </div>
          <div class="col-lg-9 col-md-10">
            <p class="a2">{api3?.Description ?? ""}</p>
          </div>
        </div>
      </div>
      <div class="bg1">
        <div class="container">
          <div class="row">{tb_data}</div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="toast-container position-fixed bottom-0 end-0 p-3">
          {toasts.map((toast) => (
            <Toast
              key={toast.id}
              message={toast.message}
              color={toast.color}
              onClose={() => handleToastClose(toast.id)}
            />
          ))}
        </div>
      </div>{" "}
      <CustomModal
        isOpen={isModalVisible}
        onRequestClose={hideModal}
        page="doctorPage"
        drDetail={drDetail}
        addToast={addToast}
      />
      <Footer />
      <script src="js/bootstrap.bundle.min.js"></script>
    </>
  );
}

export default Specialitydetail;
