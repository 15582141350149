 import React from 'react'
 import errImg from "./404-default.webp"
import Footer from '../footer'
import Header from '../header'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
 export default function Error() {
   return (
    <>
       <Header />
    <div className="container text-center">
      <div className="row justify-content-center">
        <div className="col-lg-9 col-md-10" style={{ margin: '5% 0 10% 0' }}>
          <img src={errImg} width="80%" height="auto" style={{ maxWidth: '400px', height: 'auto' }} alt="Error" />
          <h1 className="a1">Oops! Page Not Found.</h1>
          <p className="a2">We cannot find the page you are looking for.</p>
          <Link to="/" className="btn btn-primary btn-special-cta">Back to Homepage</Link> 
          <Link to="/contactUs" className="btn btn-secondary btn-special-cta">Contact Us</Link> 
        </div>
      </div>
    </div>
    <Footer></Footer>
    </>
   )
 }
 