// src/components/FadeCarousel.js
import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './FadeCarousel.css'; // Custom CSS for fade effect

const FadeCarousel = React.memo(({ Api1, baseUrlImg }) => {
  const options = {
    loop: true,
    center: true,
    responsiveClass: true,
    nav: true,
    startPosition: 1,
    items: 1,
    margin: 20,
    autoplay: true,
    dots: false,
    autoplayTimeout: 5000,
    smartSpeed: 2500,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1170: {
        items: 1,
        nav: true,
      },
    },
  };

  return Api1?.homePageCrausel ? (
    <OwlCarousel  id="fade-carousel" className="owl-theme fade-carousel" {...options}>
      {Api1.homePageCrausel.map((e, index) => (
        <div
          key={index}
          className="main_canvas"
          style={{
            backgroundImage: `url(${`${baseUrlImg}/public/${e?.img ?? ''}`})`,
          }}
        >
          <div className="main_canvas_cover">
            <div className="container">
              <div className="row main_canvas_holder">
                <div className="col-lg-6">
                  <h1>{e?.title}</h1>
                  <p>
                    {e?.description} <span>{e?.phoneNo}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </OwlCarousel>
  ) : null;
});

export default FadeCarousel;
