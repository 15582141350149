import React, { useState, useEffect } from "react";
import axios from "axios";
import "./css/bootstrap.css";
import "./headers.css";
import "./privacy.css";
import { useHistory } from "react-router-dom";
import Header from "./header.js";
import Footer from "./footer.js";
import image6 from "./img/breadcrumb-bg.png";
import { Link } from "react-router-dom";
function Privacypolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />

      <div
        class="page-bg-title"
        style={{ "background-image": `url(${image6})` }}
      >
        <div class="container">
          <h1>Privacy Policy</h1>
        </div>
      </div>

      <div class="b1">
        <div class="container">
          <div aria-label="breadcrumb">
            <nav>
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Privacy Policy
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div class="bg1">
        <div class="container">
          <div class="row">
            <div class="col-md-9">
              {/* <!--Policy Section Start Here--> */}
              <section class="contact-section padding-100">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-10 col-md-12">
                      <div class="about-content-area">
                        <h4 class="a3">Last Update: 29 Jan, 2024</h4>
                        <div class="padding-top-20 padding-bottom-30">
                          <p class="p1" />
                          Vibrant Hospital
                          <br />
                          Plot No.1, Sector 100, Dwarka Expressway, Huda W.T.P.,
                          Opp. Hanuman Mandir Gurugram- 122006, Haryana, India
                          <br />
                          Email: vibranthospitalgurgaon@gmail.com,
                          <br />
                          Phone: +91 920 600 8400
                          <p />
                          <p class="p1">
                            On the collection, use, and disclosure of the
                            information that we collect
                            <br />
                            when you use our website (
                            https://www.vibranthospital.com/ ). By accessing or
                            using our Service, you are consenting to the
                            collection, use, and disclosure of your information
                            in accordance with this Privacy Policy. If you do
                            not consent to the same, please do not access or use
                            the booking.
                          </p>
                          <p class="p1">
                            We may modify this Privacy Policy at any time
                            without any prior notice to you and will post the
                            revised Privacy Policy on the Service. The revised
                            Policy will be effective 180 days from when the
                            revised Policy is posted in the Service and your
                            continued access or use of the Service after such
                            time will constitute your acceptance of the revised
                            Privacy Policy. We, therefore, recommend that you
                            periodically review this page.
                          </p>
                          <p class="p2">
                            <strong>1. Information We Collect:</strong>
                          </p>
                          <p class="p1">
                            We will collect and process the following personal
                            information about you:
                          </p>
                          <ol class="ol1">
                            <ol class="ol1">
                              <li class="li3">
                                <span class="s1">Name</span>
                              </li>
                              <li class="li4">
                                <span class="s3">Email</span>
                              </li>
                              <li class="li4">
                                <span class="s3">Mobile</span>
                              </li>
                              <li class="li4">
                                <span class="s3">Address</span>
                              </li>
                            </ol>
                          </ol>
                          <p class="p2">
                            <strong>2. How We Collect Your Information:</strong>
                          </p>
                          <p class="p1">
                            We collect/receive information about you in the
                            following manner:
                          </p>
                          <ol class="ol1">
                            <ol class="ol1">
                              <li class="li4">
                                <span class="s3">
                                  When a user fills up the registration form or
                                  otherwise submits personal information
                                </span>
                              </li>
                              <li class="li4">
                                <span class="s3">
                                  Interacts with the website
                                </span>
                              </li>
                              <li class="li4">
                                <span class="s3">From public sources</span>
                              </li>
                            </ol>
                          </ol>
                          <p class="p2">
                            <strong>3. How We Use Your Information:</strong>
                          </p>
                          <p class="p1">
                            We will use the information that we collect about
                            you for the following purposes:
                          </p>
                          <ol class="ol1">
                            <li class="li4">
                              <span class="s3">Marketing/ Promotional</span>
                            </li>
                            <li class="li4">
                              <span class="s3">Creating user account</span>
                            </li>
                            <li class="li4">
                              <span class="s3">Testimonials</span>
                            </li>
                            <li class="li4">
                              <span class="s3">
                                Customer feedback collection
                              </span>
                            </li>
                            <li class="li4">
                              <span class="s3">Support</span>
                            </li>
                            <li class="li4">
                              <span class="s3">Targeted advertising</span>
                            </li>
                            <li class="li4">
                              <span class="s3">Manage customer order</span>
                            </li>
                            <li class="li4">
                              <span class="s3">Manage user account</span>
                            </li>
                          </ol>
                          <p class="p1">
                            If we want to use your information for any other
                            purpose, we will ask you for consent and will use
                            your information only upon receiving your consent
                            and then, only for the purpose(s) for which grants
                            consent unless we are required to do otherwise by
                            law.
                          </p>
                          <p class="p2">
                            <strong>4. How We Share Your Information:</strong>
                          </p>
                          <p class="p1">
                            We will not transfer your personal information to
                            any third party without seeking your consent, except
                            in limited circumstances as described below:
                          </p>
                          <ol class="ol1">
                            <li class="li4">
                              <span class="s3">Analytics</span>
                            </li>
                            <li class="li4">
                              <span class="s3">
                                Data collection &amp; process
                              </span>
                            </li>
                          </ol>
                          <p class="p1">
                            We require such third parties to use the personal
                            information we transfer to them only for the purpose
                            for which it was transferred and not to retain it
                            for longer than is required for fulfilling the said
                            purpose.
                          </p>
                          <p class="p1">
                            We may also disclose your personal information for
                            the following: (1) to comply with applicable law,
                            regulation, court order, or another legal process;
                            (2) to enforce your agreements with us, including
                            this Privacy Policy; or (3) to respond to claims
                            that your use of the Service violates any
                            third-party rights. If the Service or our company is
                            merged or acquired with another company, your
                            information will be one of the assets that is
                            transferred to the new owner.
                          </p>
                          <p class="p2">
                            <strong>5. Retention Of Your Information:</strong>
                          </p>
                          <p class="p1">
                            We will retain your personal information with us for
                            90 days to 2 years after users terminate their
                            accounts or for as long as we need it to fulfill the
                            purposes for which was collected as detailed in this
                            Privacy Policy. We may need to retain certain
                            information for longer periods such as
                            record-keeping / reporting in accordance with
                            applicable law or for other legitimate reasons like
                            enforcement of legal rights, fraud prevention, etc.
                            Residual anonymous information and aggregate
                            information, neither of which identifies you
                            (directly or indirectly), may be stored
                            indefinitely.
                          </p>
                          <p class="p2">
                            <strong>6. Your Rights:</strong>
                          </p>
                          <p class="p1">
                            Depending on the law that applies, you may have a
                            right to access and rectify or erase your personal
                            data or receive a copy of your personal data,
                            restrict or object to the active processing of your
                            data, ask us to share (port) your personal
                            information to another entity, withdraw any consent
                            you provided to us to process your data, a right to
                            lodge a complaint with statutory authority and such
                            other rights as may be relevant under applicable
                            laws. To exercise these rights, you can write to us
                            at&nbsp;hospitalvibrant
                            <a href="mailto:vibranthospital@gmail.com">
                              <span class="s4">@gmail.com</span>
                            </a>{" "}
                            or use our contact form{" "}
                            <a href="https://vibranthospital.com/contact-us/">
                              <span class="s4">here</span>
                            </a>
                            . We will respond to your request in accordance with
                            applicable law.
                          </p>
                          <p class="p1">
                            Do note that if you do not allow us to collect or
                            process the required personal information or
                            withdraw the consent to process the same for the
                            required purposes, you may not be able to access or
                            use the services for which your information was
                            sought.
                          </p>
                          <p class="p2">
                            <strong>7. Cookies Etc.</strong>
                          </p>
                          <p class="p1">
                            To learn more about how we use these and your
                            choices in relation to these tracking technologies,
                            please refer to our{" "}
                            <span class="s4">Cookie Policy.</span>
                          </p>
                          <p class="p2">
                            <strong>8. Security:</strong>
                          </p>
                          <p class="p1">
                            The security of your information is important to us
                            and we will use reasonable security measures to
                            prevent the loss, misuse, or unauthorized alteration
                            of your information under our control. However,
                            given the inherent risks, we cannot guarantee
                            absolute security and consequently, we cannot ensure
                            or warrant the security of any information you
                            transmit to us and you do so at your own risk.
                          </p>
                          <p class="p2">
                            <strong>
                              9. Grievance / Data Protection Officer:
                            </strong>
                          </p>
                          <p class="p1">
                            If you have any queries or concerns about the
                            processing of your information that is available
                            with us, you may email our Grievance Officer at
                            Vibrant Hospital, Plot No.1, Sector 100, Dwarka
                            Expressway, Huda W.T.P.,&nbsp;Opp. Hanuman Mandir,
                            Gurugram- 122006, Haryana
                            <span class="Apple-converted-space">
                              &nbsp;&nbsp;
                            </span>
                            email:{" "}
                            <a href="mailto:vibranthospital@gmail.com">
                              <span class="s4">hospitalvibrant@gmail.com</span>
                            </a>
                            . We will address your concerns in accordance with
                            applicable law.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* <!--Policy Section End Here--> */}
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <script src="js/bootstrap.bundle.min.js"></script>
    </>
  );
}

export default Privacypolicy;
