import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import "react-calendar/dist/Calendar.css";
// CSS
import "./css/bootstrap.css";
import "./headers.css";
// IMAGES
import image1 from "./img/logo.png";
//COMPONENT
import { Toast } from "./Toast/Toast";
import CustomModal from "../Modal/CustomModal";
let baseUrl = process.env.REACT_APP_IN_USE_LIVE_URL;
let baseUrlImg = process.env.REACT_APP_IN_USE_LIVE_URL_IMG;


const Header = () => {
  let history = useHistory();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [apidata, setapidata] = useState([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const showModal = () => setModalVisible(true);
  const hideModal = () => setModalVisible(false);
  function redirecttohome() {
    history.push("/");
  }

  const toggleDropdown = (e) => {
    e.preventDefault();
    setIsDropdownOpen(!isDropdownOpen);
  };

  const [toasts, setToasts] = useState([]);

  const addToast = (message, color) => {
    setToasts((prevToasts) => [
      ...prevToasts,
      { id: Date.now(), message, color },
    ]);
  };

  const handleToastClose = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  // Example time slots

  const currentURL = window.location.href;

  const redirectToservices = (item, e) => {
    e.preventDefault();

    let give = decodeURIComponent(item.Name);
    const formattedUrl = give.replace(/\s+/g, "-").toLowerCase();
    history.push({
      pathname: `/service/${item.url}`,
      state: { item },
    });
  };

  useEffect(() => {
    callApi5();
  }, []);

  const callApi5 = () => {
    axios
      .post(
        `${baseUrl}/getServiceCategoryByUser/`
      )
      .then((res) => {
        if (res.data.success == "1") {
          setapidata(res.data.data);
        }
      });
  };

  let tb_dat = apidata.map((item, index) => {
    return (
      <li key={index}>
        <a href={`/service/${item.url}`} style={{textDecoration:'none'}}>
          <button
            onClick={(e) => {
              redirectToservices(item, e);
            }}
            className="dropdown-item"
          >
            <img
              src={`${baseUrlImg}/public/${item.Icon}`}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "https://placehold.co/20x20";
              }}
              alt="Icon"
              style={{ width: "20px", height: "20px", marginRight: "5px" }} // Example inline styles
            />
            {item.Name}
          </button>
        </a>
      </li>
    );
  });

  return (
    <header>
      <nav
        class="navbar navbar-expand-lg main-nav"
        style={{ "background-color": "#ecf1f5" }}
      >
        <div class="container-fluid">
          <Link to="/" class="navbar-brand" onClick={redirecttohome}>
            <img
              src={image1}
              fetchpriority="high"
              alt="Vibrant Hospital Logo"
              height="40"
            />
          </Link>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div
            class="collapse navbar-collapse home-navbar"
            id="navbarNavAltMarkup"
          >
            <div class="navbar-nav">
              <Link to="/" class="nav-link">
                Home
              </Link>
              <Link to="/about" class="nav-link">
                About Us
              </Link>
              <div
                className={
                  "nav-item dropdown " + (isDropdownOpen ? "show" : "")
                }
              >
                <button
                  className="nav-link dropdown-toggle"
                  id="navbarDropdownServices"
                  onClick={(e) => {
                    toggleDropdown(e);
                  }}
                >
                  Services
                </button>
                <ul
                  className={"dropdown-menu " + (isDropdownOpen ? "show" : "")}
                  aria-labelledby="navbarDropdownServices"
                  style={{textDecoration:'none'}}
                >
                  {tb_dat}
                  {/* Add more service items as needed */}
                </ul>
              </div>
              {/* <Link to="/serviceList" class="nav-link">
                Services
              </Link> */}
              <Link to="/career" class="nav-link">
                Career
              </Link>
              <Link to="/contactUs" class="nav-link">
                Contact us
              </Link>
              <button
                class="btn btn-primary btn-special-cta"
                style={{ "margin-right": "1rem" }}
                onClick={showModal}
              >
                Get Appointment
              </button>

              {/* <a href="#" class="btn btn-secondary btn-special-cta">
                Login
              </a> */}
            </div>
          </div>
          <div className="container mt-5">
            <div className="toast-container position-fixed bottom-0 end-0 p-3">
              {toasts.map((toast) => (
                <Toast
                  key={toast.id}
                  message={toast.message}
                  color={toast.color}
                  onClose={() => handleToastClose(toast.id)}
                />
              ))}
            </div>
          </div>
        </div>
        <CustomModal isOpen={isModalVisible} onRequestClose={hideModal}  addToast={addToast}/>
      </nav>
    </header>
  );
};

export default Header;
