import React from "react";
import image8 from "../../img/ico-iso.png";
import image9 from "../../img/ico-nabh.png";
import image10 from "../../img/ico-247.png";
export default function ReportCardSection({
  secondlastitle,
  secondlistdescription 
}) {

 
  return (
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <h2 class="a1">{secondlastitle}</h2>
          <p class="a4">{secondlistdescription}</p>
        </div>
        <div class="col-lg-1"></div>
        <div class="col-lg-6">
          <div class="row">
            <div class="col-md-4" style={{ "margin-top": "7rem" }}>
              <div class="home_feature_tab1">
                100+
                <span>Patients Everyday</span>
              </div>
              <div class="home_feature_tab1">
                100+
                <span>Team of Medical Staff</span>
              </div>
            </div>
            <div class="col-md-4" style={{ "margin-top": "3rem" }}>
              <div class="home_feature_tab1">
                100+
                <span>Diagnosis Everyday</span>
              </div>
              <div class="home_feature_tab1">
                <img
                  src={image8}
                  width="50"
                  height="50"
                  loading="lazy"
                  alt="Diagnosis Everyday"
                />
                <span>Feature Five</span>
              </div>
            </div>
            <div class="col-md-4" style={{ "margin-top": "0rem" }}>
              <div class="home_feature_tab1">
                <img
                  src={image9}
                  width="50"
                  height="50"
                  loading="lazy"
                  alt="NABH Accreditation"
                />
                <span>NABH Accreditation</span>
              </div>
              <div class="home_feature_tab1">
                <img
                  src={image10}
                  width="50"
                  height="50"
                  loading="lazy"
                  alt="24/7 Emergency"
                />
                <span>24/7 Emergency</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
